import React, { useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../../selectors/event'
import {
  Button,
  darken,
  InputBase,
  lighten,
  SvgIcon,
  Typography,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { mdiCamera, mdiFileGifBox } from '@mdi/js'
import GifPickerModal from '../../modals/GifPickerModal'
import { useMultimediaLogic } from '../../EventCreation/MultimediaLogic'
import DropZone from '../../editEvent/DropZone'
import MultimediaRow from '../media/MultimediaRow'
import { Add } from '@mui/icons-material'
import { shuffleArray } from '../../RsvpWizard/InfoStep'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  avatar: {
    borderRadius: 100,
    width: 36,
    height: 36,
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  topRow: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  topText: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  statusIcon: {
    width: 12,
    height: 12,
  },
  statusGoing: {
    color: theme.palette.secondary.main,
  },
  statusMaybe: {
    color: theme.palette.primary.main,
  },
  statusCant: {
    color: theme.palette.red.main,
  },
  inputContainer: {
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing('1px', 1.5, 1.5, 1.5),
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    background: theme.palette.background.input,
  },
  input: {
    padding: '0px !important',
  },
  inputLeft: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: 2,
    overflow: 'hidden',
  },
  dropZone: {
    marginTop: theme.spacing(1.5),
  },
  inputRight: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexShrink: 0,
  },
  inputButton: {
    width: 24,
    height: 24,
    minWidth: 0,
    color:
      theme.extras?.name === 'custom'
        ? theme.palette.grey[800]
        : theme.palette.mode === 'light'
          ? theme.palette.grey[600]
          : theme.palette.grey[300],
  },
  visibilityCaption: {
    color: theme.palette.text.tertiary,
  },
  suggestedContainer: {
    display: 'flex',
    alignItems: 'stretch',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    gap: 6,
  },
  addMessageButton: {
    fontWeight: 400,
    flexShrink: 0,
    padding: theme.spacing(1, 1.5, 1, 1.5),
    borderRadius: '2px 20px 20px 20px',
    background:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.main, 0.9)
        : darken(theme.palette.primary.main, 0.9),
    color:
      theme.palette.mode === 'light'
        ? darken(theme.palette.primary.main, 0.3)
        : lighten(theme.palette.primary.main, 0.3),
    '&:hover': {
      background:
        theme.palette.mode === 'light'
          ? lighten(theme.palette.primary.main, 0.8)
          : darken(theme.palette.primary.main, 0.8),
    },
  },
  suggestedMessage: {
    flexShrink: 0,
    padding: theme.spacing(0, 1.5, 0, 2),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 10,
  },
}))

const AddRsvpMessageInput = ({
  inputAlwaysEnabled,
  text,
  setText,
  media,
  setMedia,
  status,
}) => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const [gifOpen, setGifOpen] = useState(false)
  const [messageEnabled, setMessageEnabled] = useState(
    inputAlwaysEnabled || text !== '',
  )

  const imageInputRef = useRef(null)

  const allGoingTexts = [
    t('suggestedMessagesGoing.1'),
    t('suggestedMessagesGoing.2'),
    t('suggestedMessagesGoing.3'),
    t('suggestedMessagesGoing.4'),
  ]
  const allMaybeTexts = [
    t('suggestedMessagesMaybe.1'),
    t('suggestedMessagesMaybe.2'),
    t('suggestedMessagesMaybe.3'),
    t('suggestedMessagesMaybe.4'),
  ]
  const allCantTexts = [
    t('suggestedMessagesCant.1'),
    t('suggestedMessagesCant.2'),
    t('suggestedMessagesCant.3'),
    t('suggestedMessagesCant.4'),
  ]

  const [goingTexts, setGoingTexts] = useState(
    shuffleArray(allGoingTexts).slice(0, 4),
  )
  const [maybeTexts, setMaybeTexts] = useState(
    shuffleArray(allMaybeTexts).slice(0, 4),
  )
  const [cantTexts, setCantTexts] = useState(
    shuffleArray(allCantTexts).slice(0, 4),
  )

  const textsToUse = () => {
    if (status === 'GOING') return goingTexts
    if (status === 'MAYBE') return maybeTexts
    if (status === 'CANT') return cantTexts
    return goingTexts
  }

  const { uploadImage, onRemoveImageClicked } = useMultimediaLogic({
    media: media,
    setMedia: setMedia,
    dispatch: dispatch,
    event: event,
  })

  const onGifSelected = async (url) => {
    setGifOpen(false)
    const file = await fetch(url)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], 'filename', {
            type: 'image/gif',
          }),
      )

    await uploadImage([file])
  }

  const addMediaClicked = () => {
    imageInputRef.current?.click()
  }

  const onEnableMessage = (text) => {
    setMessageEnabled(true)
    setText(text)
  }

  return (
    <div className={classes.root}>
      {!messageEnabled && (
        <div className={classes.suggestedContainer}>
          <Button
            className={classes.addMessageButton}
            startIcon={<Add />}
            onClick={() => onEnableMessage('')}
          >
            {t('addMessage')}
          </Button>

          {textsToUse().map((text, i) => (
            <Button
              className={classes.suggestedMessage}
              onClick={() => onEnableMessage(text)}
            >
              <Typography variant='body2' style={{ fontWeight: 500 }}>
                {text}
              </Typography>
            </Button>
          ))}
        </div>
      )}
      {messageEnabled && (
        <div className={classes.inputContainer}>
          <div className={classes.inputLeft}>
            <InputBase
              multiline
              maxRows={8}
              autoFocus={text === ''}
              placeholder={t('rsvpMessagePlaceholder')}
              value={text}
              onChange={(e) => setText(e.target.value)}
              style={{ padding: 0 }}
              inputProps={{
                className: classes.input,
              }}
            />

            <DropZone
              customAccept='video/mp4, video/quicktime, video/webm, image/jpeg, image/png, image/gif, image/webp'
              multiple
              fileInputRef={imageInputRef}
              onNewFile={uploadImage}
              className={cx(media.length > 0 ? classes.dropZone : null)}
              overlayClassName={undefined}
              videoActive={undefined}
              allowVideo={undefined}
              title={undefined}
              fullScreen={undefined}
              disabled={undefined}
              withPaste={undefined}
            >
              <MultimediaRow
                onRemoveImageClicked={onRemoveImageClicked}
                media={media}
                limit={3}
              />
            </DropZone>
          </div>

          <div className={classes.inputRight}>
            <Button
              className={classes.inputButton}
              onClick={() => setGifOpen(true)}
            >
              <SvgIcon>
                <path d={mdiFileGifBox} />
              </SvgIcon>
            </Button>

            <Button className={classes.inputButton} onClick={addMediaClicked}>
              <SvgIcon>
                <path d={mdiCamera} />
              </SvgIcon>
            </Button>
          </div>

          <GifPickerModal
            onGifSelected={onGifSelected}
            open={gifOpen}
            onClose={() => setGifOpen(false)}
          />
        </div>
      )}
      {messageEnabled && (
        <Typography variant='caption' className={classes.visibilityCaption}>
          <Trans
            i18nKey={'rsvpMessageVisibilityCaption'}
            t={t}
            components={{
              bold: <i />,
            }}
          />
        </Typography>
      )}
    </div>
  )
}

export default AddRsvpMessageInput
