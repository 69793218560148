import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../../selectors/event'
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import DropZone from 'components/editEvent/DropZone'
import {
  setEditingPost,
  setEventUpdatesModal,
  setImagesChatModal,
} from 'actions/modals'
import FullScreenSheet from '../../modals/FullScreenSheet'
import apiClient from '../../../shared-components/utils/ApiClient'
import { ArrowDropDown, KeyboardArrowDown } from '@mui/icons-material'
import YesNoDialog from '../../dialog/YesNoDialog'
import PostEventUpdates from '../posts/PostEventUpdates'
import { defaultEditingPost } from '../../../reducers/modals'
import {
  EditorComponent,
  Remirror,
  useChainedCommands,
  useHelpers,
  useRemirror,
  useRemirrorContext,
} from '@remirror/react'
import DescriptionMenu from '../../EventCreation/DescriptionMenu'
import {
  BoldExtension,
  BulletListExtension,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  MarkdownExtension,
  OrderedListExtension,
  UnderlineExtension,
} from 'remirror/extensions'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ChooseImageModal from '../../modals/ChooseImageModal'
import { DndProvider } from 'react-dnd'
import { useMultimediaLogic } from '../../EventCreation/MultimediaLogic'
import MultimediaViewer from '../media/MultimediaViewer'
import { useUser } from '../../../utils/userFunctions'
import GifPickerModal from 'components/modals/GifPickerModal'
import BashButton, {
  BashButtonType,
} from '../../../shared-components/buttons/BashButton'
import { remirrorStyles } from '../posts/Comment'

const useStyles = makeStyles()((theme) => ({
  dropZone: {
    flexGrow: 1,
    height: 'unset',
    minHeight: '0px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 480,
    flexGrow: 1,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      maxHeight: 600,
    },
  },
  postAsHostRoot: {
    height: '100%',
  },
  input: {
    width: '100%',
    padding: 0,
    '& > :first-of-type': {
      overflowY: 'auto !important',
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.input,
    borderRadius: theme.spacing(1.25),
    width: '100%',
    overflow: 'hidden',
  },
  hostSelection: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '0px !important',
    backgroundColor: theme.palette.background.input,
    borderRadius: theme.spacing(1),
  },
  photoInsert: {
    color: theme.palette.primary[800],
    width: 20,
    height: 20,
  },
  boxBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    minHeight: 68,
  },
  sendButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.primary[600],
    },
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
  imageListContainer: {
    overflow: 'auto',
    display: 'flex',
    maxWidth: 136,
  },
  imageListItem: {
    overflow: 'hidden',
    '& > img': {
      objectFit: 'cover',
      border: '1px solid ' + theme.palette.grey.dark,
    },
    position: 'relative',
  },
  removeImageButton: {
    position: 'absolute',
    left: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
  },
  image: {
    width: 208,
    height: 208,
    borderRadius: 0,
    marginBottom: theme.spacing(3),
  },
  smallImage: {
    width: 48,
    height: 48,
    border: '1px solid ' + theme.palette.grey.dark,
    marginRight: theme.spacing(1),
    objectFit: 'cover',
  },
  avatar: {
    height: 32,
    width: 32,
    border: '1px solid ' + theme.palette.grey.dark,
    backgroundColor: 'white',
  },
  disabledLine: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dividerTop: {
    background: theme.palette.grey.main,
    height: 1,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  hostBox: {
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginLeft: theme.spacing(1),
  },
  hostName: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  smallImageButton: {
    height: 40,
    borderRadius: 1000,
    padding: 0,
    marginRight: theme.spacing(1),
    background: theme.palette.primary[100],
  },
  selectedImage: {
    border: '2px solid ' + theme.palette.primary[950],
  },
  hostButton: {},
  disabledButton: {
    backgroundColor: theme.palette.grey.main,
  },
  nonHostMessageContainer: {
    marginTop: theme.spacing(10),
  },
  hostMenuItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  hostMenuAvatar: {
    width: '20px',
    height: '20px',
    borderRadius: '100px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 2, 2),
    borderBottom: '1px solid ' + theme.palette.grey[150],
  },
  closeIcon: {
    cursor: 'pointer',
  },
  middle: {
    overflow: 'hidden',
    // height: '100%',
    flexGrow: 1,
    // overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingBottom: 0,
    gap: theme.spacing(2.5),
  },
  infoIcon: {
    height: 16,
    width: 16,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  remirrorContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > :nth-of-type(2)': {
      flexGrow: 1,
    },
  },
  remirror: {
    height: '100%',
    outline: 'none',
    overflowY: 'auto',
    padding: theme.spacing(1.5),
    ...remirrorStyles(theme),
  },
  editorMenu: {
    margin: theme.spacing(0, 0),
  },
  mediaScroller: {
    padding: theme.spacing(0, 2, 0, 2),
    height: 'unset',
    maxWidth: '100%',
    overflow: 'auto',
    paddingRight: theme.spacing(2),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  postAs: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
  },
  chevronDown: {
    marginLeft: theme.spacing(0.5),
  },
  eventUpdatesContainer: {
    margin: theme.spacing(2),
  },
}))

// Sets the textMarkdown and text fields of the editingPost whenever an edit is made in the Remirror component
const TextUpdater = ({ state, open }) => {
  const { getMarkdown, getText } = useHelpers()
  const dispatch = useDispatch()
  const editingPost = useSelector((state) => state.modals.editingPost)

  useEffect(() => {
    if (open) {
      dispatch(
        setEditingPost({
          ...editingPost,
          textMarkdown: getMarkdown(state),
          text: getText().replaceAll('\u0000', '\n'),
        }),
      )
    }
  }, [state, open])

  return <div />
}

// Sets the content of the Remirror component whenever a new post is selected
const InitialMarkdownSetter = ({}) => {
  const editingPost = useSelector((state) => state.modals.editingPost)
  const [hasSet, setHasSet] = useState(false)

  const { setContent } = useRemirrorContext()
  const chain = useChainedCommands()

  useEffect(() => {
    if (editingPost.id && !hasSet) {
      if (editingPost.textMarkdown && editingPost.textMarkdown.length > 0) {
        // Clear text and set content to markdown
        setContent({
          type: 'doc',
          content: [],
        })
        chain.insertMarkdown(editingPost.textMarkdown).run()
      } else {
        setContent({
          type: 'doc',
          content: [],
        })
        chain.insertMarkdown(editingPost.text).run()
      }

      setHasSet(true)
    } else if (!editingPost.id && !hasSet) {
      setContent({
        type: 'doc',
        content: [],
      })
      setHasSet(true)
    }
  }, [editingPost.id, hasSet])

  useEffect(() => {
    setHasSet(false)
  }, [editingPost.id])

  return <div />
}

const CreatePostModal = ({ open, onClose, onSendMessage }) => {
  const { user, ready, isLoggedIn } = useUser()
  const { classes, cx } = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const myGuest = event.myGuest
  const dispatch = useDispatch()
  const [infoOpen, setInfoOpen] = useState(false)
  const [gifOpen, setGifOpen] = useState(false)

  const onSend = (message) => {
    onSendMessage(message)
  }

  const fileInputRef = useRef()
  // const onImagesSelected = (files) => {
  //   const images = Array.from(files).map((e) => {
  //     return {
  //       file: e,
  //       message: '',
  //       id: e.name,
  //     }
  //   })
  //   dispatch(setImagesChatModal(images))
  // }

  const editingPost = useSelector((state) => state.modals.editingPost)
  const eventUpdates = editingPost.eventUpdates
  const fromOrganisation = editingPost.fromOrganisation
  const fromGuest = editingPost.fromGuest
  const imageInputRef = useRef(null)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [madeChange, setMadeChange] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const autoFocusImageInput = useSelector(
    (state) => state.modals.autoFocusImageInput,
  )
  const postAsHost = useSelector((state) => state.modals.postAsHost)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const menuOpen = Boolean(anchorEl)

  const { uploadImage, onRemoveImageClicked, uploadMedia } = useMultimediaLogic(
    {
      media: editingPost.media,
      setMedia: (newMedia) => {
        dispatch(
          setEditingPost({
            ...editingPost,
            media: newMedia,
          }),
        )
      },
      dispatch: dispatch,
      event: event,
    },
  )

  const onSetImageClicked = () => {
    setImageModalOpen(true)
  }

  const hasGuestThatCanPost = ['JOINED', 'GOING', 'MAYBE', 'CANT'].includes(
    event.myGuest?.status,
  )

  let posters = postAsHost
    ? (event.hosts?.filter(
        (host) => host.type === 'ORGANISATION' && host.model.manager,
      ) ?? [])
    : []

  if (hasGuestThatCanPost && !postAsHost) {
    posters.push({ id: 'GUEST', type: 'GUEST', model: event.myGuest })
  }

  const linkExtension = new LinkExtension({
    autoLink: true,
    selectTextOnClick: true,
    defaultTarget: '_blank',
    defaultProtocol: 'https:',
  })
  const { manager, onChange, state } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new UnderlineExtension(),
      new HeadingExtension(),
      new HardBreakExtension(),
      new BulletListExtension(),
      new OrderedListExtension(),
      new MarkdownExtension(),
      linkExtension,
    ],
    content:
      editingPost.textMarkdown && editingPost.textMarkdown?.trim?.() !== ''
        ? editingPost.textMarkdown
        : editingPost.text,
    stringHandler:
      editingPost.textMarkdown != null &&
      editingPost.textMarkdown.trim?.() !== ''
        ? 'markdown'
        : 'text',
  })

  useEffect(() => {
    let fromOrganisation = null
    let fromGuest = null
    let myOrgs = postAsHost
      ? (event.hosts?.filter(
          (host) => host.type === 'ORGANISATION' && host.model.manager,
        ) ?? [])
      : []
    if (editingPost?.id) {
      if (editingPost.poster?.type === 'ORGANISATION') {
        fromOrganisation = editingPost.poster.model
      }
    } else if (myOrgs[0]) {
      fromOrganisation = event.hosts[0]?.model
    } else if (hasGuestThatCanPost) {
      fromGuest = event.myGuest
    }

    dispatch(
      setEditingPost({
        ...editingPost,
        fromOrganisation: fromOrganisation,
        fromGuest: fromGuest,
      }),
    )

    if (editingPost.id && editingPost.textMarkdown) {
    }
  }, [open])

  const handleClick = (event) => {
    if (posters.length > 1) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const clearEditor = () => {
    manager.view.updateState(manager.createState({ content: '' }))
  }

  const send = async () => {
    setLoading(true)

    const body = {
      ...editingPost,
      eventUpdateIds: editingPost.eventUpdates.map((e) => e.id),
      parentEventId: event.id,
      fromOrganisationId: editingPost.fromOrganisation?.id,
    }
    const res = editingPost.id
      ? await apiClient.event.patchPost(editingPost.id, body, myGuest?.code)
      : await apiClient.event.sendPost(body, myGuest?.code)

    if (res.id) {
      await uploadMedia(null, res.id)
    }

    onSend({
      ...res,
      media: editingPost.media.map((m) => ({
        ...m,
        uploaded: true,
      })),
      content: {
        ...res.content,
        model: {
          ...res.content?.model,
          guest: {
            ...res.content?.model?.guest,
            user: user,
          },
        },
      },
    })

    dispatch(setEditingPost(defaultEditingPost))
    dispatch(setImagesChatModal([]))
    dispatch(setEventUpdatesModal([]))
    setLoading(false)
    clearEditor()
    onClose()
  }

  const inputImageRef = useRef(null)

  useEffect(() => {
    if (autoFocusImageInput) {
      inputImageRef?.current?.focus()
    }
  }, [autoFocusImageInput])

  const onCloseModal = () => {
    if (editingPost.id) {
      if (madeChange) {
        setDeleteDialogOpen(true)
      } else {
        dispatch(setEditingPost(defaultEditingPost))
        clearEditor()
        onClose()
      }
    } else {
      if (editingPost.text && editingPost.text.length > 0) {
        setDeleteDialogOpen(true)
      } else if (eventUpdates && eventUpdates.length > 0) {
        setDeleteDialogOpen(true)
      } else if (editingPost.media && editingPost.media.length > 0) {
        setDeleteDialogOpen(true)
      } else {
        dispatch(setEditingPost(defaultEditingPost))
        clearEditor()
        onClose()
      }
    }
  }

  const onDeleteConfirm = () => {
    setDeleteDialogOpen(false)
    clearEditor()
    onClose()
  }

  const getSaveButtonText = () => {
    if (editingPost.id) return t('save')
    if (postAsHost) return t('sendUpdate')
    return t('comment')
  }

  const onGifSelected = async (url) => {
    setGifOpen(false)
    const file = await fetch(url)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], 'filename', {
            type: 'image/gif',
          }),
      )

    await uploadImage([file])
  }

  return (
    <FullScreenSheet
      classes={{
        paper: cx(classes.root, postAsHost && classes.postAsHostRoot),
      }}
      open={open}
      onClose={onCloseModal}
      title={
        postAsHost
          ? t(editingPost.id ? 'edit' : 'sendAnUpdate')
          : t(editingPost.id ? 'edit' : 'comment')
      }
    >
      <DropZone
        className={classes.dropZone}
        fileInputRef={fileInputRef}
        onNewFile={uploadImage}
        multiple
        title='Upload'
        fullScreen
      >
        <div className={cx(classes.root, postAsHost && classes.postAsHostRoot)}>
          <YesNoDialog
            title={t('deletePost')}
            subtitle={t('deletePostSub')}
            confirmText={t('delete')}
            cancelText={t('cancel')}
            destructive
            open={deleteDialogOpen}
            onConfirm={onDeleteConfirm}
            onClose={() => setDeleteDialogOpen(false)}
          />

          <div className={classes.middle}>
            {postAsHost && (
              <Typography variant='body2' sx={{ color: 'grey.800' }}>
                {t('sendUpdateExplanation')}
              </Typography>
            )}
            <Box
              className={classes.inputContainer}
              sx={{
                flexGrow: postAsHost ? 1 : 0,
                '& .remirror-editor-wrapper': {
                  overflowY: 'auto',
                  height: '100%',
                },
              }}
            >
              <div className={classes.remirrorContainer}>
                <Remirror
                  manager={manager}
                  classNames={[classes.remirror]}
                  state={state}
                  onChange={onChange}
                  autoFocus={true}
                >
                  <DescriptionMenu
                    className={cx(classes.editorMenu)}
                    withImages={true}
                    onAddImage={() => setImageModalOpen(true)}
                    onAddGif={() => setGifOpen(true)}
                  />
                  <EditorComponent />
                  <TextUpdater state={state} open={open} />
                  <InitialMarkdownSetter />
                </Remirror>
              </div>
              {/*{selectedImageFile && (*/}
              {/*  <div className={classes.imageListItem}>*/}
              {/*    <img*/}
              {/*      className={classes.image}*/}
              {/*      src={URL.createObjectURL(selectedImageFile.file)}*/}
              {/*      alt='image'*/}
              {/*    />*/}
              {/*    <IconButton*/}
              {/*      size='small'*/}
              {/*      onClick={() => removeFile(selectedImageFile)}*/}
              {/*      className={classes.removeImageButton}*/}
              {/*    >*/}
              {/*      <Close />*/}
              {/*    </IconButton>*/}
              {/*  </div>*/}
              {/*)}*/}
              <div className={classes.eventUpdatesContainer}>
                <PostEventUpdates
                  post={{
                    eventUpdates: eventUpdates,
                  }}
                  onRemove={(id) => {
                    dispatch(
                      setEditingPost({
                        ...editingPost,
                        eventUpdates: editingPost.eventUpdates.filter(
                          (u) => u.id !== id,
                        ),
                      }),
                    )
                  }}
                />
              </div>
            </Box>
          </div>

          {editingPost.media.length > 0 && (
            <MultimediaViewer
              hideMainViewer
              mediaToUse={editingPost.media}
              imageInputRef={imageInputRef}
              onNewFile={uploadImage}
              onSetImageClicked={onSetImageClicked}
              onRemoveImageClicked={onRemoveImageClicked}
              className={classes.mediaScroller}
              mediaSize={120}
            />
          )}

          <div className={classes.boxBottom}>
            {posters.length > 0 && (
              <div className={classes.hostSelection}>
                <Button
                  className={classes.hostButton}
                  onClick={handleClick}
                  disabled={posters.length <= 1}
                >
                  <Avatar
                    className={classes.avatar}
                    src={
                      fromOrganisation?.avatarUrls?.lg ??
                      fromGuest?.user?.avatarUrls?.lg ??
                      fromGuest?.avatarUrls?.lg ??
                      user.avatarUrls?.lg
                    }
                  />
                  <div className={classes.hostBox}>
                    <Typography className={classes.hostName} variant='body2'>
                      {fromOrganisation?.name ??
                        fromGuest?.user?.name ??
                        fromGuest?.name ??
                        user?.name}
                    </Typography>
                  </div>

                  {posters.length > 1 && (
                    <ArrowDropDown className={classes.chevronDown} />
                  )}
                </Button>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={menuOpen}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {posters.map((host) => {
                    return (
                      <MenuItem
                        key={host.id}
                        className={classes.hostMenuItem}
                        onClick={() => {
                          handleMenuClose()
                          dispatch(
                            setEditingPost({
                              ...editingPost,
                              fromOrganisation:
                                host.type === 'ORGANISATION'
                                  ? host.model
                                  : null,
                              fromGuest:
                                host.type === 'GUEST' ? host.model : null,
                            }),
                          )
                        }}
                      >
                        <img
                          src={
                            host.model?.avatarUrls?.lg ??
                            host.model?.user?.avatarUrls?.lg ??
                            (host.type === 'GUEST' && user.avatarUrls?.lg)
                          }
                          className={classes.hostMenuAvatar}
                          alt='logo'
                        />
                        <Typography variant='body2'>
                          {host.model?.name ?? host.model?.user?.name}
                        </Typography>
                      </MenuItem>
                    )
                  })}
                </Menu>
              </div>
            )}

            <BashButton
              type={BashButtonType.PRIMARY}
              onClick={send}
              enabled={
                editingPost.text.length > 0 || editingPost.media.length > 0
              }
              loading={loading}
            >
              {getSaveButtonText()}
            </BashButton>
          </div>
        </div>
      </DropZone>
      <DndProvider backend={HTML5Backend}>
        <ChooseImageModal
          open={imageModalOpen}
          onClose={() => setImageModalOpen(false)}
          inputRef={imageInputRef}
          onNewFile={uploadImage}
          mediaToUse={editingPost.media}
          setMedia={(newMedia) => {
            dispatch(
              setEditingPost({
                ...editingPost,
                media: newMedia,
              }),
            )
          }}
        />
      </DndProvider>
      <GifPickerModal
        onGifSelected={onGifSelected}
        open={gifOpen}
        onClose={() => setGifOpen(false)}
      />
    </FullScreenSheet>
  )
}

export default CreatePostModal
