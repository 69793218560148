import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../../selectors/event'
import {
  getGuestAvatarUrls,
  getGuestName,
} from '../../../shared-components/utils/eventDisplay'
import { SvgIcon, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MaybeIcon from '../../../svg/status/Maybe'
import CantIcon from '../../../svg/status/Cant'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../utils/userFunctions'
import BottomSheet from '../../common/BottomSheet'
import FlatButton from '../../../shared-components/buttons/FlatButton'
import { useMultimediaLogic } from '../../EventCreation/MultimediaLogic'
import apiClient from '../../../shared-components/utils/ApiClient'
import { setSnackbar } from '../../../shared-components/redux/notifications/actions'
import { editEvent } from '../../../actions/event'
import AddRsvpMessageInput from './AddRsvpMessageInput'

const mediaSize = 80

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  avatar: {
    borderRadius: 100,
    width: 36,
    height: 36,
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  topRow: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  topText: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  statusIcon: {
    width: 12,
    height: 12,
  },
  statusGoing: {
    color: theme.palette.secondary.main,
  },
  statusMaybe: {
    color: theme.palette.primary.main,
  },
  statusCant: {
    color: theme.palette.red.main,
  },
  dropZone: {
    marginTop: theme.spacing(1.5),
  },
  messageInput: {
    marginLeft: -24,
  },
}))

const AddRsvpMessageModal = ({ open, onClose, text, setText, onSend }) => {
  const dispatch = useDispatch()
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const myGuest = event.myGuest
  const { user, isLoggedIn } = useUser()
  const [_media, setMedia] = useState([])
  const media = _media.filter((m) => !m.removed)
  const [saving, setSaving] = useState(false)

  const { uploadMedia } = useMultimediaLogic({
    media: media,
    setMedia: (newMedia) => {
      setMedia(newMedia)
    },
    dispatch: dispatch,
    event: event,
  })

  const onSave = async () => {
    if (text === '' && media.length === 0) {
      dispatch(setSnackbar('info', t('pleaseAddAMessage')))
    }
    setSaving(true)

    const body = {
      text: text,
      parentEventId: event.id,
      reactToRsvp: true,
    }
    let res = null
    try {
      res = await apiClient.event.sendPost(body, myGuest?.code)

      if (res.id) {
        await uploadMedia(null, res.id)
      }
    } catch (e) {
      console.error(e)
      setSaving(false)
      return
    }

    onSend({
      ...res,
      media: media.map((m) => ({
        ...m,
        uploaded: true,
      })),
      content: {
        ...res.content,
        model: {
          ...res.content?.model,
          guest: {
            ...res.content?.model?.guest,
            user: user,
          },
        },
      },
    })

    dispatch(
      editEvent({
        ...event,
        myGuest: {
          ...event.myGuest,
          hasRsvpPosted: true,
        },
      }),
    )

    setSaving(false)
    onClose()
  }

  if (!myGuest) return null

  return (
    <BottomSheet open={open} onClose={onClose}>
      <div className={cx(classes.root)}>
        <div className={classes.topRow}>
          <img
            className={classes.avatar}
            src={
              isLoggedIn ? user.avatarUrls?.lg : getGuestAvatarUrls(myGuest)?.lg
            }
            alt='Guest Avatar'
          />
          <div className={classes.topText}>
            <Typography variant='body2'>
              <span style={{ fontWeight: 500 }}>
                {isLoggedIn ? user.name : getGuestName(myGuest)}
              </span>
              {` ${t(`eventLogUpdate.NEW_RSVP_${myGuest.status}`)}`}
            </Typography>
            {['JOINED', 'GOING'].includes(myGuest.status) && (
              <CheckCircleIcon
                className={cx(classes.statusIcon, classes.statusGoing)}
                style={{ color: event.theme.colourScheme?.colorButtonPrimary }}
              />
            )}
            {myGuest.status === 'MAYBE' && (
              <SvgIcon
                component={MaybeIcon}
                className={cx(classes.statusIcon, classes.statusMaybe)}
              />
            )}
            {myGuest.status === 'CANT' && (
              <SvgIcon
                component={CantIcon}
                className={cx(classes.statusIcon, classes.statusCant)}
              />
            )}
          </div>
        </div>
        <AddRsvpMessageInput
          inputAlwaysEnabled
          text={text}
          setText={setText}
          media={media}
          setMedia={setMedia}
          status={myGuest?.status}
        />

        <FlatButton style={{
          color: 'white'
        }} fullWidth onClick={onSave} loading={saving}>
          {t('add')}
        </FlatButton>
      </div>
    </BottomSheet>
  )
}

export default AddRsvpMessageModal
