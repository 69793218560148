import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, SvgIcon, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import mixpanel from 'shared-components/utils/mixpanel'
import WizardTextField from './WizardTextField'

import {
  getActiveGuestWithoutNew,
  getCurrentEvent,
  getCurrentJoinOption,
  getHasResponded,
} from 'selectors/event'
import {
  editNewGuest,
  onChangeAddGuestMessage,
  onChangeAddGuestName,
  rsvpWaitList,
  setEmail,
  setNewGuestStatus,
  onChangeAddGuestFirstName,
  onChangeAddGuestLastName,
} from 'actions/rsvp'
import MaybeIcon from '../../svg/status/Maybe'
import CantIcon from '../../svg/status/Cant'
import { validate } from 'email-validator'
import { useIsLoggedIn } from '../../utils/hooks'
import apiClient from '../../shared-components/utils/ApiClient'
import { RsvpStatus } from 'types/types'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  CheckCircle,
  ChevronRight,
  ExpandMore,
  InfoOutlined,
} from '@mui/icons-material'
import AddRsvpMessageInput from '../event/posts/AddRsvpMessageInput'
import { rsvpFlow } from '../../actions/event'
import dayjs from 'dayjs'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../shared-components/buttons/BashButton'
import { useRouter } from 'next/router'
import {
  palettePrimary,
  paletteRed,
  paletteSecondary,
} from 'shared-components/colors'
import EventTickets from 'components/event/EventTickets'

const useStyles = makeStyles()((theme) => ({
  root: {
    // padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    // minHeight: 600
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  nameFields: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1.5),
    },
  },
  nameField: {
    marginBottom: 0,
  },
  divider: {
    marginRight: '-24px',
    marginLeft: '-24px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  nextButton: {
    flexGrow: 1,
    height: 40,
  },
  emailField: {},
  statusField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  statusIcon: {
    height: 48,
    width: 48,
    border: '1px solid ' + theme.palette.grey.dark,
    borderRadius: 100,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: 'visible',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  goingIcon: {
    color:
      theme.palette.mode === 'dark'
        ? paletteSecondary.dark.main
        : paletteSecondary.light.main,
  },
  goingSelected: {
    background:
      theme.palette.mode === 'dark'
        ? paletteSecondary.dark[100]
        : paletteSecondary.light[100],
    border:
      '2px solid ' +
      (theme.palette.mode === 'dark'
        ? paletteSecondary.dark[800]
        : paletteSecondary.light[800]),
  },
  maybeIcon: {
    color:
      theme.palette.mode === 'dark'
        ? palettePrimary.dark.main
        : palettePrimary.light.main,
    marginRight: '2px',
  },
  maybeSelected: {
    background:
      theme.palette.mode === 'dark'
        ? palettePrimary.dark[100]
        : palettePrimary.light[100],
    border:
      '2px solid ' +
      (theme.palette.mode === 'dark'
        ? palettePrimary.dark[800]
        : palettePrimary.light[800]),
  },
  cantIcon: {
    color:
      theme.palette.mode === 'dark'
        ? paletteRed.dark.main
        : paletteRed.light.main,
  },
  cantSelected: {
    background:
      theme.palette.mode === 'dark'
        ? paletteRed.dark[100]
        : paletteRed.light[100],
    border:
      '2px solid ' +
      (theme.palette.mode === 'dark'
        ? paletteRed.dark[800]
        : paletteRed.light[800]),
  },
  svgIcon: {
    width: '30px',
    height: '30px',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    gap: theme.spacing(8),
  },
  scrollContainer: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '&:empty': {
      padding: 0,
    },
  },
  stripe: {
    padding: 4,
    paddingTop: 12,
  },
  paymentAfter: {
    lineHeight: '21px',
  },
  paymentAfterContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  dividerStripe: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  infoIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  marginTop1: {
    marginTop: theme.spacing(1),
  },
}))

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

const InfoStep = ({}) => {
  const stripe = useStripe()
  const elements = useElements()
  const { classes, cx } = useStyles()
  const router = useRouter()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const [loading, setLoading] = useState(false)
  const activeGuest = useSelector(getActiveGuestWithoutNew)
  const user = useSelector((state) => state.user.user)
  const hasResponded = useSelector(getHasResponded)
  const newGuestStatus = useSelector((state) => state.rsvp.newGuest.status)
  const newGuest = useSelector((state) => state.rsvp.newGuest)
  const addGuestName = useSelector((state) => state.rsvp.newGuest.name)
  const addGuestFirstName = useSelector(
    (state) => state.rsvp.newGuest.firstName,
  )
  const addGuestLastName = useSelector((state) => state.rsvp.newGuest.lastName)
  const addGuestEmail = useSelector((state) => state.rsvp.newGuest.emailAddress)
  const addGuestMessage = useSelector((state) => state.rsvp.newGuest.message)
  const waitingList = useSelector((state) => state.rsvp.newGuest.waitingList)
  const selectedTicketOption = useSelector(getCurrentJoinOption)
  const [invalidFirstName, setInvalidFirstName] = useState(false)
  const [invalidLastName, setInvalidLastName] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const isLoggedIn = useIsLoggedIn()
  const hasBashTickets = event.joinOptions && event.joinOptions.length > 0
  const allFree =
    (selectedTicketOption?.ticketOption.price === 0 ||
      selectedTicketOption?.ticketOption.price == null) &&
    !selectedTicketOption?.ticketOption?.chooseYourOwnPrice

  const [postText, setPostText] = useState('')
  const [_postMedia, setPostMedia] = useState([])
  const postMedia = _postMedia.filter((m) => !m.removed)

  const availableTickets = event.joinOptions?.filter((t) => t.available)
  const noAvailableTickets = !availableTickets || availableTickets.length === 0
  const ticketsAvailableLater = event.joinOptions?.find(
    (e) => e.availableFrom && dayjs(e.availableFrom).isAfter(dayjs()),
  )

  const showAddMessage =
    !noAvailableTickets &&
    activeGuest?.status !== newGuestStatus &&
    event.invitedBy != null

  const handleError = (error) => {
    setLoading(false)
    setErrorMessage(error.message)
  }

  const nameInputRef = useRef()
  const emailInputRef = useRef()
  const messageInputRef = useRef()

  useEffect(() => {
    if (
      addGuestMessage === '' &&
      activeGuest?.message != null &&
      activeGuest?.message !== ''
    ) {
      onChangeMessage({
        target: {
          value: activeGuest?.message,
        },
      })
    }
  }, [])

  const onSubmitName = useCallback(() => {
    mixpanel.people.set('$name', addGuestName)

    focusEmail()
  }, [addGuestName, dispatch])

  const onSubmitEmail = () => {
    if (event.chatGroup && event.chatGroup.state !== 'ADMIN_ONLY') {
      focusMessage()
    } else {
      onRsvp()
    }
  }

  const newGuestStatusIsCantOrMaybe =
    newGuestStatus === RsvpStatus.CANT || newGuestStatus === RsvpStatus.MAYBE
  const cantOrMaybe =
    activeGuest?.status === RsvpStatus.CANT ||
    activeGuest?.status === RsvpStatus.MAYBE
  const approvalRequired =
    selectedTicketOption?.ticketOption?.approvalRequired &&
    !newGuestStatusIsCantOrMaybe
  const showStripe =
    selectedTicketOption?.ticketOption?.approvalRequired &&
    ((selectedTicketOption?.price != null && selectedTicketOption?.price > 0) ||
      (selectedTicketOption.ticketOption?.price != null &&
        selectedTicketOption.ticketOption?.price > 0)) &&
    !newGuestStatusIsCantOrMaybe

  const onRsvp = async () => {
    if (!isLoggedIn) {
      if (!addGuestFirstName || addGuestFirstName.trim().length === 0) {
        setInvalidFirstName(true)
        return
      }
      if (!addGuestLastName || addGuestLastName.trim().length === 0) {
        setInvalidLastName(true)
        return
      }
      if (!addGuestEmail || !validate(addGuestEmail)) {
        setInvalidEmail(true)
        return
      }
    }

    dispatch(
      editNewGuest({
        ...newGuest,
        media: postMedia,
        postText: postText !== '' || postMedia?.length > 0 ? postText : null,
      }),
    )

    let stripeCustomerId = null

    if (showStripe) {
      setLoading(true)

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit()
      if (submitError) {
        handleError(submitError)
        return
      }

      const { clientSecret, customerId } = await apiClient.ticket.setupIntent(
        event.myGuest?.code,
        addGuestEmail,
        addGuestName,
      )
      stripeCustomerId = customerId

      if (!stripeCustomerId) {
        // Error?
        return
      }

      // Confirm the SetupIntent using the details collected by the Payment Element
      const { error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
      })
      console.log(error)
    }

    apiClient.general.analyticsEventEvent('complete-rsvp-info', {
      eventId: event.id,
      userId: user?.id,
    })

    setLoading(true)

    if (waitingList) {
      await dispatch(
        rsvpWaitList('web_event_page', isLoggedIn ? null : event.myGuest?.code),
      )
    } else {
      await dispatch(
        rsvpFlow(newGuestStatus, 'web_event_page', stripeCustomerId),
      )
      // await dispatch(
      //   rsvp(event.myGuest?.code, 'web_event_page', stripeCustomerId)
      // )
    }

    setLoading(false)
    // dispatch(closeAndResetRsvpModal())
    // if (
    //   newGuestStatus === 'GOING' &&
    //   (!hasBashTickets || (hasBashTickets && allFree))
    // ) {
    //   dispatch(showConfetti())
    // }
  }

  const onChangeEmail = useCallback(
    (event) => {
      setInvalidEmail(false)
      dispatch(setEmail(event.target.value))
    },
    [dispatch],
  )

  const onChangeMessage = useCallback(
    (event) => {
      dispatch(onChangeAddGuestMessage(event.target.value))
    },
    [dispatch],
  )

  const onChangeStatus = useCallback(
    (status) => {
      dispatch(setNewGuestStatus(status))
    },
    [dispatch],
  )

  const focusEmail = () => {
    emailInputRef.current && emailInputRef.current.focus()
  }
  const focusMessage = () => {
    messageInputRef.current &&
      messageInputRef.current.scrollIntoView({ behavior: 'smooth' })
    messageInputRef.current && messageInputRef.current.focus()
  }

  const getTitle = () => {
    if (!hasBashTickets || allFree) {
      if (noAvailableTickets) {
        return ticketsAvailableLater ? t('preregister') : t('waitList')
      }
      if (['GOING', 'JOINED'].includes(newGuestStatus)) return t('going')
      if (newGuestStatus === 'MAYBE') return t('maybe')
      if (newGuestStatus === 'CANT') return t('cantGo')
      return t('going')
    } else {
      return selectedTicketOption?.ticketOption.name
    }
  }

  const showChevron = () => {
    if (selectedTicketOption) {
      const { ticketOption } = selectedTicketOption
      if (
        (ticketOption.price != null && ticketOption.price > 0) ||
        (ticketOption.chooseYourOwnPrice &&
          (selectedTicketOption.price ?? 0) > 0)
      ) {
        return 'right'
      }
    }
    return 'none'
  }

  const buttonText = () => {
    if (newGuestStatusIsCantOrMaybe) {
      return t('respondRsvp')
    }
    if (selectedTicketOption) {
      const { ticketOption } = selectedTicketOption
      if (ticketOption.approvalRequired) {
        return t('actionJoinRequest')
      }
      if (
        (ticketOption.price != null && ticketOption.price > 0) ||
        (ticketOption.chooseYourOwnPrice &&
          (selectedTicketOption.price ?? 0) > 0)
      ) {
        return selectedTicketOption.quantity > 1
          ? t('getTickets')
          : t('getTicket')
      }
    }
    return t('respondRsvp')
  }

  const onChangeFirstName = useCallback(
    (event) => {
      setInvalidFirstName(false)
      const newFirstName = event.target.value
      dispatch(onChangeAddGuestFirstName(newFirstName))
      dispatch(
        editNewGuest({
          ...newGuest,
          firstName: newFirstName,
          lastName: addGuestLastName || '',
          name: `${newFirstName} ${addGuestLastName || ''}`.trim(),
        }),
      )
    },
    [dispatch, addGuestLastName, newGuest],
  )

  const onChangeLastName = useCallback(
    (event) => {
      setInvalidLastName(false)
      const newLastName = event.target.value
      dispatch(onChangeAddGuestLastName(newLastName))
      dispatch(
        editNewGuest({
          ...newGuest,
          firstName: addGuestFirstName || '',
          lastName: newLastName,
          name: `${addGuestFirstName || ''} ${newLastName}`.trim(),
        }),
      )
    },
    [dispatch, addGuestFirstName, newGuest],
  )

  const showTicketSelection =
    cantOrMaybe && newGuestStatus === 'JOINED' && hasBashTickets && !showStripe

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant='body2' style={{ fontWeight: 500 }}>
          {event.name}
        </Typography>

        <Typography variant='h6'>{getTitle()}</Typography>
      </div>
      {cantOrMaybe && (
        <div className={classes.statusContainer}>
          <div
            className={classes.statusField}
            onClick={() =>
              onChangeStatus(approvalRequired ? 'REQUESTED' : 'JOINED')
            }
          >
            <div
              className={cx(
                classes.statusIcon,
                (newGuestStatus === 'JOINED' ||
                  newGuestStatus === 'REQUESTED') &&
                  classes.goingSelected,
              )}
            >
              <SvgIcon
                component={CheckCircle}
                className={cx(classes.goingIcon, classes.svgIcon)}
              />
            </div>
            <Typography variant='body2'>{t('going')}</Typography>
          </div>
          <div
            className={classes.statusField}
            onClick={() => onChangeStatus('MAYBE')}
          >
            <div
              className={cx(
                classes.statusIcon,
                newGuestStatus === 'MAYBE' && classes.maybeSelected,
              )}
            >
              <SvgIcon
                component={MaybeIcon}
                className={cx(classes.maybeIcon, classes.svgIcon)}
              />
            </div>
            <Typography variant='body2'>{t('maybe')}</Typography>
          </div>
          <div className={classes.statusField}>
            <div
              className={classes.statusField}
              onClick={() => onChangeStatus('CANT')}
            >
              <div
                className={cx(
                  classes.statusIcon,
                  newGuestStatus === 'CANT' && classes.cantSelected,
                )}
              >
                <SvgIcon
                  component={CantIcon}
                  className={cx(classes.cantIcon, classes.svgIcon)}
                />
              </div>
              <Typography variant='body2'>{t('cantGo')}</Typography>
            </div>
          </div>
        </div>
      )}
      <div className={classes.scrollContainer}>
        {showAddMessage && (
          <div className={classes.messageContainer}>
            <AddRsvpMessageInput
              text={postText}
              setText={setPostText}
              media={postMedia}
              setMedia={setPostMedia}
              status={newGuestStatus}
            />
          </div>
        )}

        {!hasResponded && !isLoggedIn && (
          <div className={classes.nameFields}>
            <WizardTextField
              title={t('firstName') + '*'}
              placeholder={t('firstNamePlaceholder')}
              value={addGuestFirstName}
              onChange={onChangeFirstName}
              onSubmit={onSubmitName}
              className={cx(
                classes.nameField,
                !showAddMessage && classes.marginTop1,
              )}
              inputRef={nameInputRef}
              helperText={invalidFirstName ? t('invalidFirstName') : undefined}
              autoFocus={true}
            />
            <WizardTextField
              title={t('lastName') + '*'}
              placeholder={t('lastNamePlaceholder')}
              value={addGuestLastName}
              onChange={onChangeLastName}
              onSubmit={onSubmitName}
              className={cx(
                classes.nameField,
                !showAddMessage && classes.marginTop1,
              )}
              helperText={invalidLastName ? t('invalidLastName') : undefined}
            />
          </div>
        )}

        {!hasResponded && !isLoggedIn && (
          <WizardTextField
            title={t('emailAddressRequired')}
            placeholder={t('emailExample')}
            value={addGuestEmail}
            full
            helperText={invalidEmail ? t('invalidEmail') : undefined}
            type='email'
            onChange={onChangeEmail}
            onSubmit={onSubmitEmail}
            className={classes.emailField}
            inputRef={emailInputRef}
            inputProps={{
              autocomplete: 'email',
            }}
          />
        )}
        {showStripe && (
          <div className={classes.stripe}>
            <PaymentElement />
            {errorMessage && <div>{errorMessage}</div>}
            <Divider className={classes.dividerStripe} />
            <div className={classes.paymentAfterContainer}>
              <InfoOutlined className={classes.infoIcon} />
              <Typography className={classes.paymentAfter} variant='body2'>
                {t('paymentAfterAccept')}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div className={classes.buttonsContainer}>
        {showTicketSelection && (
          <EventTickets
            selectedRsvpStatus={newGuestStatus}
            setSelectedRsvpStatus={onChangeStatus}
            noPadding
          />
        )}
        {!showTicketSelection && (
          <BashButton
            type={BashButtonType.SECONDARY_DARK}
            onClick={() => onRsvp()}
            className={classes.lowJoinButton}
          >
            {buttonText()}
          </BashButton>
        )}
        {/*{inWidget && (*/}
        {/*  <Typography variant='body2' className={classes.continueInBashText}>*/}
        {/*    s*/}
        {/*  </Typography>*/}
        {/*)}*/}
      </div>
    </div>
  )
}

export default InfoStep
