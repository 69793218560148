import { Button, Menu, MenuItem, SvgIcon } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getCurrentEvent } from '../../../selectors/event'
import React, { useState } from 'react'
import { useRouter } from 'next/router'
import apiClient from '../../../shared-components/utils/ApiClient'
import Typography from '@mui/material/Typography'
import { ChevronRight } from '@mui/icons-material'
import { decapitalizeFirstLetter } from '../../../shared-components/utils/copy'
import {
  getGuestAvatarUrls,
  getGuestName,
} from '../../../shared-components/utils/eventDisplay'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MaybeIcon from '../../../svg/status/Maybe'
import CantIcon from '../../../svg/status/Cant'
import FlatButton from '../../../shared-components/buttons/FlatButton'
import BottomSheet from '../../common/BottomSheet'
import { editEvent } from '../../../actions/event'
import { setSnackbar } from '../../../shared-components/redux/notifications/actions'
import BashButton, { BashButtonType } from 'shared-components/buttons/BashButton'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  guestRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(2, 1.5),
    background: theme.palette.grey[150],
    borderRadius: 8,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 100,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  dropDown: {
    marginLeft: 'auto',
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 8,
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  dropDownLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  cantColor: {
    color: '#EE6C4D',
  },
  maybeColor: {
    color: '#2E60DC',
  },
  goColor: {
    color: '#8B30F3',
  },
  chevronRight: {
    transform: 'rotate(90deg)',
  },
  saveButton: {
    height: 40,
    alignSelf: 'flex-end',
  },
  menuRoot: {
    // width: '100%',
    maxWidth: '359px',
    [theme.breakpoints.down('md')]: {
      left: '0 !important',
    },
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    padding: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  cancelButton: {
    borderRadius: 100,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    height: 40,
  },
  confirmButton: {
    borderRadius: 100,
    border: `1px solid ${theme.palette.red.main}`,
    color: theme.palette.red.main,
    height: 40,
  },
  confirmButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(4),
    justifyContent: 'flex-end',
  },
  givingTicketWarning: {
    marginTop: theme.spacing(-2),
    color: theme.palette.grey[800],
  },
}))

export const updateStatusCounts = (
  event,
  dispatch,
  guest,
  fromStatus,
  toStatus,
) => {
  let toSubtract = ''
  let toAdd = ''
  if (fromStatus === toStatus) {
    return
  }
  if (fromStatus === 'JOINED') toSubtract = 'joined'
  if (fromStatus === 'GOING') toSubtract = 'going'
  if (fromStatus === 'MAYBE') toSubtract = 'maybe'
  if (fromStatus === 'CANT') toSubtract = 'cant'
  if (fromStatus === 'REQUESTED') toSubtract = 'requested'
  if (!fromStatus) {
    if (guest.hype) {
      toSubtract = 'interested'
    } else {
      if (guest.waitingList) {
        toSubtract = 'waitingList'
      } else {
        toSubtract = 'invited'
      }
    }
  }

  if (toStatus === 'JOINED') toAdd = 'joined'
  if (toStatus === 'GOING') toAdd = 'going'
  if (toStatus === 'MAYBE') toAdd = 'maybe'
  if (toStatus === 'CANT') toAdd = 'cant'
  if (toStatus === 'REQUEST_DENIED') toAdd = 'requestDenied'

  dispatch(
    editEvent({
      ...event,
      statusCountsHost: {
        ...event.statusCountsHost,
        [toSubtract]: Math.max(event.statusCountsHost?.[toSubtract] - 1, 0),
        [toAdd]: event.statusCountsHost?.[toAdd] + 1,
      },
    }),
  )
}

const GuestManagementChangeStatus = ({ guest, setGuest, onBack }) => {
  const dispatch = useDispatch()
  const { classes, cx } = useStyles()
  const event = useSelector(getCurrentEvent)
  const router = useRouter()
  const { t } = useTranslation('common')
  const [localStatus, setLocalStatus] = useState(
    ['GOING', 'MAYBE', 'CANT'].includes(guest.status)
      ? guest.status
      : guest.status === 'JOINED'
        ? 'GOING'
        : 'NONE',
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirming, setConfirming] = useState(false)

  const showGivingTicketWarning =
    event.hasJoinOptions &&
    ['GOING', 'JOINED'].includes(localStatus) &&
    !['GOING', 'JOINED'].includes(guest.status)

  const hasMultipleJoinOptions = (event.joinOptions?.length ?? 0) > 1

  const secondaryText = () => {
    const ticketName =
      hasMultipleJoinOptions && guest.tickets
        ? (guest.tickets?.[0]?.name ?? t('guestList')) +
          ` (${guest.status === 'REQUESTED' ? guest.tickets?.length : guest.totalTicketCount})`
        : null
    const answers = guest.questionsCompleted ? t('questions.answers') : null
    const answersText =
      ticketName == null
        ? answers
        : answers == null
          ? null
          : decapitalizeFirstLetter(answers)

    return [ticketName, answersText].filter((s) => s != null).join(' + ')
  }

  const onDropdownClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onDropdownItemClick = (status) => {
    setLocalStatus(status)
    handleMenuClose()
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const onConfirmClose = () => {
    setConfirmOpen(false)
  }

  const onConfirmSave = async () => {
    setConfirming(true)
    if (localStatus === 'GOING' && event.hasJoinOptions) {
      await apiClient.guest.giveTickets(guest.id, 1)
      setGuest({
        ...guest,
        totalTicketCount: 1,
        activeTicketCount: 1,
        status: 'JOINED',
      })
      updateStatusCounts(event, dispatch, guest, guest.status, 'JOINED')
    } else {
      await apiClient.guest.patch(guest.id, {
        status: localStatus,
      })
      setGuest({
        ...guest,
        status: localStatus,
        totalTicketCount: 0,
      })
      updateStatusCounts(event, dispatch, guest, guest.status, localStatus)
    }

    setConfirming(false)
    setConfirmOpen(false)
    onBack()
  }

  const onSaveClick = async () => {
    if (localStatus === 'NONE') {
      dispatch(setSnackbar('info', t('selectAStatus')))
      return
    }

    const selectedJoinOption = event.joinOptions?.find(
      (j) => j.id === guest.tickets?.[0]?.joinOptionId,
    )

    const ticketWasPaid =
      selectedJoinOption == null || selectedJoinOption.price > 0
    if (
      ['JOINED', 'GOING'].includes(guest.status) &&
      ['MAYBE', 'CANT'].includes(localStatus)
    ) {
      if (ticketWasPaid) {
        setConfirmOpen(true)
      } else {
        onConfirmSave()
      }
      return
    }
    if (
      [
        'MAYBE',
        'CANT',
        null,
        undefined,
        'REQUESTED',
        'REQUEST_DENIED',
      ].includes(guest.status) &&
      localStatus === 'GOING'
    ) {
      onConfirmSave()
      return
    }

    if (['MAYBE', 'CANT'].includes(localStatus)) {
      await onConfirmSave()
    }

    onBack()
  }

  return (
    <div className={classes.root}>
      <div className={classes.guestRoot}>
        <img
          alt='Guest avatar'
          src={getGuestAvatarUrls(guest).lg}
          className={classes.avatar}
        />
        <div className={classes.text}>
          <Typography variant='body1' style={{ fontWeight: 500 }}>
            {getGuestName(guest)}
          </Typography>
          <Typography variant='caption'>{secondaryText()}</Typography>
        </div>

        <Button
          className={cx(classes.dropDown, classes.dropDownLabel)}
          onClick={onDropdownClick}
        >
          {['JOINED', 'GOING'].includes(localStatus) && (
            <CheckCircleIcon className={classes.goColor} />
          )}
          {localStatus === 'MAYBE' && (
            <SvgIcon component={MaybeIcon} className={classes.maybeColor} />
          )}
          {localStatus === 'CANT' && (
            <SvgIcon component={CantIcon} className={classes.cantColor} />
          )}
          <Typography
            variant='body2'
            style={{
              marginLeft: localStatus === 'NONE' ? 6 : 'unset',
            }}
          >
            {t(`shared:dropdownRsvp.${localStatus}`)}
          </Typography>

          <ChevronRight className={classes.chevronRight} />
        </Button>
      </div>
      {showGivingTicketWarning && (
        <Typography className={classes.givingTicketWarning} variant='caption'>
          {t('givingTicketWarning')}
        </Typography>
      )}
      <BashButton
        type={BashButtonType.PRIMARY}
        onClick={onSaveClick}
        className={classes.saveButton}
        loading={confirming}
      >
        {t('changeStatus')}
      </BashButton>
      <Menu
        style={{ minHeight: '0px' }}
        classes={{
          paper: classes.menuRoot,
          list: classes.menuList,
        }}
        id='create-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        disableAutoFocusItem
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => onDropdownItemClick('GOING')}
        >
          <CheckCircleIcon className={classes.goColor} />
          <Typography variant='body2'>
            {t('shared:dropdownRsvp.GOING')}
          </Typography>
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onDropdownItemClick('MAYBE')}
        >
          <SvgIcon component={MaybeIcon} className={classes.maybeColor} />
          <Typography variant='body2'>
            {t('shared:dropdownRsvp.MAYBE')}
          </Typography>
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onDropdownItemClick('CANT')}
        >
          <SvgIcon component={CantIcon} className={classes.cantColor} />
          <Typography variant='body2'>
            {t('shared:dropdownRsvp.CANT')}
          </Typography>
        </MenuItem>
      </Menu>
      <BottomSheet
        open={confirmOpen}
        onClose={onConfirmClose}
        title={t(
          localStatus === 'GOING'
            ? 'addToGuestListTitle'
            : 'cancelTicketsTitle',
        )}
      >
        <Typography variant='body1' style={{ lineHeight: '24px' }}>
          {t(
            localStatus === 'GOING'
              ? 'addToGuestListDescription'
              : 'cancelTicketsDescription',
          )}
        </Typography>

        <div className={classes.confirmButtons}>
          <BashButton
            type={BashButtonType.WHITE_PRIMARY_BORDERED}
            onClick={() => setConfirmOpen(false)}
          >
            {t('no')}
          </BashButton>

          <BashButton
            type={BashButtonType.WHITE_RED_BORDERED}
            onClick={onConfirmSave}
            loading={confirming}
          >
            {t(
              localStatus === 'GOING'
                ? 'addToGuestListConfirm'
                : 'cancelTicketsConfirm',
            )}
          </BashButton>
        </div>
      </BottomSheet>
    </div>
  )
}

export default GuestManagementChangeStatus
