import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { closeTicketsModal } from '../../actions/modals'
import { closeAndResetRsvpModal } from '../../actions/rsvp'
import {
  getActiveGuest,
  getCurrentEvent,
  getHasResponded,
} from '../../selectors/event'
import FlatButton from '../../shared-components/buttons/FlatButton'
import apiClient from '../../shared-components/utils/ApiClient'
import { usePrevious } from '../../shared-components/utils/hooks'
import { EURO } from '../EventCreation/TicketOption'
import TicketsStepItem from './TicketsStepItem'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    overflow: 'auto',
    marginTop: theme.spacing(3),
    maxHeight: '300px',
    paddingBottom: theme.spacing(2),
  },
  totalContainer: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid ' + theme.palette.grey[200],
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  nextButton: {
    flex: 1,
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    '& > *': {
      height: 40,
    },
  },
}))

const BuyMoreTicketSelection = () => {
  const { classes } = useStyles()
  const router = useRouter()
  const event = useSelector(getCurrentEvent)
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const [localOptions, setLocalOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const hasResponded = useSelector(getHasResponded)
  const activeGuest = useSelector(getActiveGuest)
  const currentGuestCode = activeGuest ? activeGuest.code : undefined
  const ticketsOpen = useSelector((state) => state.modals.open.tickets)
  const prevTicketsOpen = usePrevious(ticketsOpen)

  const priceInCents = localOptions.reduce(
    (total, ticket) =>
      total +
      (ticket.ticketOption.chooseYourOwnPrice
        ? ticket.price || 0
        : ticket.ticketOption.price) *
        ticket.quantity,
    0,
  )
  const totalTicketQuantity = localOptions.reduce(
    (total, ticket) => total + ticket.quantity,
    0,
  )
  const availableTickets = event.joinOptions
    ? event.joinOptions.filter((t) => t.available)
    : []
  const hasAvailableTickets = availableTickets.length > 0

  const hasChooseYourOwnWithoutPrice = localOptions.find((e) => {
    return (
      (isNaN(e.price) || (e.price < 100 && e.price != 0)) &&
      e.quantity > 0 &&
      e.ticketOption.chooseYourOwnPrice
    )
  })

  const nextDisabled =
    loading ||
    totalTicketQuantity === 0 ||
    !hasAvailableTickets ||
    hasChooseYourOwnWithoutPrice

  const onChangeItem = (id, quantity, price) => {
    const ticketOption = event.joinOptions.find((t) => t.id === id)
    const existing = localOptions.find((t) => t.ticketOptionId === id)
    const newQuantity =
      quantity != null
        ? Math.min(
            Math.max(1, quantity),
            ticketOption.quantityAvailable ?? 99999,
          )
        : (existing?.quantity ?? 0)
    const newPrice = price != null ? Math.max(0, price) : existing?.price

    if (!existing) {
      setLocalOptions([
        ...localOptions,
        {
          ticketOptionId: id,
          quantity: newQuantity,
          ticketOption: ticketOption,
          price: newPrice,
          code: ticketOption.code,
        },
      ])
    } else {
      setLocalOptions(
        localOptions.map((option) =>
          option.ticketOptionId === id
            ? {
                ticketOptionId: id,
                quantity: newQuantity,
                ticketOption: ticketOption,
                price: newPrice,
                code: ticketOption.code,
              }
            : option,
        ),
      )
    }
  }

  const nextClicked = async () => {
    setLoading(true)
    if (hasResponded) {
      const order = await apiClient.tickets.orderMoreTickets(
        localOptions,
        currentGuestCode,
      )
      dispatch(closeAndResetRsvpModal())
      router.push(
        `/events/${event.id}/checkout?gc=${currentGuestCode}&orderId=${order.id}`,
      )
    } else {
      throw Error('should not happen')
    }
  }

  useEffect(() => {
    if (ticketsOpen && prevTicketsOpen !== ticketsOpen) {
      const availableTickets =
        event.joinOptions?.filter((t) => t.available) ?? []
      const hiddenTicket = availableTickets.find((t) => t.hidden)
      if (
        (hiddenTicket || availableTickets.length === 1) &&
        localOptions.length === 0
      ) {
        const ticket = hiddenTicket ?? availableTickets[0]
        setLocalOptions([
          {
            ticketOptionId: ticket.id,
            quantity: 1,
            ticketOption: ticket,
            code: ticket.code,
          },
        ])
      }
    }
  }, [ticketsOpen, event, prevTicketsOpen])

  const selectedTicketOption = event.joinOptions?.find(
    (e) => e.myTickets && e.myTickets.length > 0,
  )

  if (!selectedTicketOption) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant='subtitle1'>{t('buyMoreTickets')}</Typography>
      </div>
      <div className={classes.scrollContainer}>
        <TicketsStepItem
          selected={true}
          onPriceChange={(price) =>
            onChangeItem(selectedTicketOption.id, null, price)
          }
          onChange={(quantity) =>
            onChangeItem(selectedTicketOption.id, quantity, null)
          }
          key={selectedTicketOption.id}
          ticket={selectedTicketOption}
          className={classes.ticketOption}
          quantitySelected={
            localOptions.find(
              (t) => t.ticketOptionId === selectedTicketOption.id,
            )?.quantity ?? 1
          }
          totalSelected={localOptions.reduce(
            (sum, { quantity }) => sum + quantity,
            0,
          )}
        >
          {selectedTicketOption?.name}
        </TicketsStepItem>
      </div>
      <div className={classes.totalContainer}>
        <Typography variant='h6'>{t('ticketStep.total')}</Typography>
        <Typography variant='h6'>{EURO.format(priceInCents / 100)}</Typography>
      </div>
      <div className={classes.buttonsContainer}>
        <BashButton
          type={BashButtonType.WHITE_GREY_BORDER}
          onClick={() => dispatch(closeTicketsModal())}
        >
          {t('cancel')}
        </BashButton>
        <BashButton
          disabled={nextDisabled}
          type={BashButtonType.PRIMARY}
          className={classes.nextButton}
          loading={loading}
          onClick={() => nextClicked()}
        >
          {t('next')}
        </BashButton>
      </div>
    </div>
  )
}

export default BuyMoreTicketSelection
