import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
import { Button, CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import {
  Check,
  Close,
  MoreVert,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import apiClient from '../../utils/ApiClient'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent, getCurrentUser } from '../../../selectors/event'
import GuestInfoSheet from '../../../components/modals/GuestInfoSheet'
import { AddFriendButton } from './GuestModal'
import { editEvent, patchGuest } from '../../../actions/event'
import { useRouter } from 'next/router'
import { useIsLoggedIn } from 'utils/hooks'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const useStyles = makeStyles()((theme) => ({
  guestItemRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    // '&:not(:last-child)': {
    //   borderBottom: `1px solid ${theme.palette.grey[200]}`,
    // },
  },
  pointer: {
    cursor: 'pointer',
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 100,
    position: 'relative',
  },
  avatarOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 100,
  },
  name: {
    fontWeight: 500,
  },
  hideMe: {
    opacity: 0.5,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifySelf: 'flex-end',
  },
  requestButton: {
    width: 32,
    height: 32,
    borderRadius: 100,
    minWidth: 0,
    minHeight: 0,
    border: '1px solid',
  },
  requestButtonLabel: {
    width: 'auto',
  },
  approveButton: {
    color: theme.palette.primary.main,
  },
  rejectButton: {
    color: theme.palette.red.main,
  },
  textDisabled: {
    color: theme.palette.text.tertiary,
  },
  disabled: {
    pointerEvents: 'none',
  },
  greyedOut: {
    opacity: 0.5,
  },
  moreButton: {
    padding: 4,
  },
}))

export const GuestItem = ({ guest, setGuest, isAnswersSheet = false }) => {
  const dispatch = useDispatch()
  const { classes, cx } = useStyles()
  const event = useSelector(getCurrentEvent)
  const router = useRouter()
  const { t } = useTranslation('common')
  const [localGuest, setLocalGuest] = useState(guest)
  const guestToUse = setGuest != null ? guest : (localGuest ?? guest)
  const setGuestToUse = setGuest ? setGuest : setLocalGuest
  const [approving, setApproving] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const [detailOpen, setDetailOpen] = useState(false)
  const isLoggedIn = useIsLoggedIn()
  const [anchorEl, setAnchorEl] = useState(null)
  const currentUser = useSelector(getCurrentUser)
  const isMe = guestToUse.mine

  const hasMultipleJoinOptions = (event.joinOptions?.length ?? 0) > 1

  const secondaryText = () => {
    if (!event.host) return null

    const parts = [
      hasMultipleJoinOptions && guestToUse.tickets?.[0]?.name != null
        ? guestToUse.tickets[0].name?.slice(0, 68)
        : null,
      (guestToUse.totalTicketCount ?? 1) > 1
        ? `${t('groupOf')} ${guestToUse.totalTicketCount}`
        : null,
    ].filter(Boolean)
    return parts.join(' · ')
  }

  const onGuestClicked = () => {
    if (event.host) {
      setDetailOpen(true)
    } else if (guestToUse?.user?.id && isLoggedIn) {
      router.push(`/users/${guestToUse?.user?.id}`)
    }
  }

  const onApprove = async () => {
    setApproving(true)
    const result = await apiClient.guest.approve(guest.id)
    setGuestToUse((prev) => ({
      ...prev,
      status: 'JOINED',
    }))
    dispatch(
      editEvent({
        ...event,
        statusCounts: {
          ...event.statusCounts,
          going: event.statusCounts?.going + 1,
          joined: event.statusCounts?.joined + 1,
          requested: event.statusCounts?.requested - 1,
        },
      }),
    )
    setApproving(false)
  }

  const onReject = async () => {
    setRejecting(true)
    const result = await apiClient.guest.reject(guest.id)
    setGuestToUse((prev) => ({
      ...prev,
      status: 'REQUEST_DENIED',
    }))
    dispatch(
      editEvent({
        ...event,
        statusCounts: {
          ...event.statusCounts,
          requested: event.statusCounts?.requested - 1,
        },
      }),
    )
    setRejecting(false)
  }

  const handleMenuClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (e) => {
    setAnchorEl(null)
  }

  const handleHideMe = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(
      patchGuest(guestToUse, { hideMe: !guestToUse.hideMe }, guestToUse.code),
    )
    setGuestToUse((prev) => ({
      ...prev,
      hideMe: !prev.hideMe,
    }))

    // Update event guests when hiding/showing
    if (!guestToUse.hideMe) {
      // If we're about to hide
      dispatch(
        editEvent({
          ...event,
          guests: event.guests.filter((g) => g.id !== guestToUse.id),
        }),
      )
    } else {
      // If we're about to show
      dispatch(
        editEvent({
          ...event,
          guests: [...event.guests, guestToUse],
        }),
      )
    }

    handleMenuClose()
  }

  const secondary = secondaryText()

  return (
    <>
      <div
        onClick={onGuestClicked}
        className={cx(
          classes.guestItemRoot,
          (event.host || guestToUse.user?.id) && isLoggedIn && classes.pointer,
          !guestToUse.user && !guestToUse.name && classes.greyedOut,
        )}
      >
        <div className={classes.avatar}>
          <img
            src={guestToUse.user?.avatarUrls?.lg ?? guestToUse.avatarUrls?.lg}
            className={cx(classes.avatar)}
            alt='Avatar'
          />
          {guestToUse.hideMe && (
            <div className={classes.avatarOverlay}>
              <VisibilityOff sx={{ color: 'white' }} />
            </div>
          )}
        </div>
        <div
          className={cx(
            classes.text,
            guestToUse.status === 'REQUEST_DENIED' && classes.textDisabled,
          )}
        >
          <Typography
            variant='subtitle1'
            className={cx(classes.name, guestToUse.hideMe && classes.hideMe)}
          >
            {guestToUse.user?.name ??
              guestToUse.name ??
              guestToUse.emailAddress}
            {isMe && (
              <Typography
                component='span'
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                {` (${t('you')})`}
              </Typography>
            )}
          </Typography>
          {secondary && (
            <Typography variant='caption' style={{ marginTop: 4 }}>
              {secondary}
            </Typography>
          )}
        </div>
        <div className={classes.right}>
          {guestToUse.status === 'REQUESTED' && (
            <>
              <Button
                className={cx(
                  classes.requestButton,
                  classes.rejectButton,
                  classes.requestButtonLabel,
                )}
                onClick={onReject}
              >
                {rejecting ? (
                  <CircularProgress color='red' size={20} />
                ) : (
                  <Close />
                )}
              </Button>

              <Button
                className={cx(
                  classes.requestButton,
                  classes.approveButton,
                  classes.requestButtonLabel,
                )}
                onClick={onApprove}
              >
                {approving ? <CircularProgress size={20} /> : <Check />}
              </Button>
            </>
          )}

          {guestToUse.status === 'JOINED' && guest.status === 'REQUESTED' && (
            <Typography variant='caption'>{t('approved')}</Typography>
          )}
          {guestToUse.status === 'REQUEST_DENIED' && (
            <Typography variant='caption'>{t('rejected')}</Typography>
          )}

          {guestToUse.status !== 'REQUESTED' && !setGuest && (
            <AddFriendButton guest={guestToUse} />
          )}

          {isMe && !isAnswersSheet && (
            <>
              <IconButton
                className={classes.moreButton}
                size='small'
                onClick={handleMenuClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleHideMe}>
                  {guestToUse.hideMe ? (
                    <Visibility sx={{ mr: 1 }} fontSize='small' />
                  ) : (
                    <VisibilityOff sx={{ mr: 1 }} fontSize='small' />
                  )}
                  {guestToUse.hideMe
                    ? t('showMeOnGuestList')
                    : t('hideFromGuestList')}
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
      <GuestInfoSheet
        onClose={() => setDetailOpen(false)}
        open={detailOpen}
        activeInfoGuest={guestToUse}
        setGuest={setGuest ?? setLocalGuest}
      />
    </>
  )
}

const GuestList = ({ guests }) => {
  return guests.map((g) => <GuestItem key={g.id} guest={g} />)
}

export default GuestList
