import React from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { IconButton, SxProps, Typography, useMediaQuery } from '@mui/material'
import { ChevronLeft, Close } from '@mui/icons-material'
import FullScreenSheetTransition from './FullScreenSheetTransition'
import { useRouter } from 'next/router'
import Dialog from '@mui/material/Dialog'

const useStyles = makeStyles()((theme) => ({
  backdropRoot: {
    backgroundColor: theme.palette.background['backdrop'],
  },
  titleContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: 64,
    flexShrink: 0,
  },
  titleBorder: {
    borderBottom: '1px solid ' + theme.palette.grey[200],
  },
  title: {
    padding: theme.spacing(0, 5),
    lineHeight: '24px',
  },
  closeButton: {
    color: theme.palette.text.primary,
    position: 'absolute',
    left: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  right: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  paper: {
    paddingBottom: 'calc(0px + env(safe-area-inset-bottom))',
    transition: 'padding 200ms ease',
    maxWidth: 480,
    width: '100%',
    background: theme.palette.background.paper,
    border: theme.palette.background.modalBorder,
  },
  smallOnMobileContainer: {
    // top: theme.spacing(8),
    borderRadius: 8,
    alignItems: 'flex-start',
  },
  smallOnMobilePaper: {
    width: '95% !important',
    height: 'auto !important',
    borderRadius: 8,
    top: theme.spacing(8),
  },
  inWidgetContainer: {
    alignItems: 'flex-start',
  },
}))

interface FullScreenSheetProps {
  title?: string
  onClose?: () => void
  onBack?: () => void
  open?: boolean
  children: React.ReactNode
  className?: string
  titleBorder?: boolean
  hideTitle?: boolean
  smallOnMobile?: boolean
  rightContent?: React.ReactNode
  smallTitle?: boolean
  sx?: SxProps<Theme>
  paperSx?: SxProps<Theme>
  disableBackdropClick?: boolean
}

const FullScreenSheet = ({
  title,
  onClose,
  onBack,
  open,
  children,
  className,
  titleBorder = true,
  hideTitle = false,
  smallOnMobile = false,
  rightContent,
  smallTitle = false,
  sx = [],
  paperSx = [],
  disableBackdropClick = false,
  ...props
}: FullScreenSheetProps) => {
  const router = useRouter()
  const { classes, cx } = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const inWidget = router.pathname.includes('/widget')

  return (
    <Dialog
      slotProps={{
        backdrop: {
          classes: {
            root: classes.backdropRoot,
          },
        },
      }}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' || !disableBackdropClick) {
          onClose?.(event as any)
        }
      }}
      classes={{
        paper: cx(
          classes.paper,
          className,
          fullScreen && smallOnMobile && classes.smallOnMobilePaper,
        ),
        container: cx(
          fullScreen && smallOnMobile
            ? classes.smallOnMobileContainer
            : undefined,
          inWidget ? classes.inWidgetContainer : undefined,
        ),
      }}
      PaperProps={{
        sx: [...(Array.isArray(paperSx) ? paperSx : [paperSx])],
      }}
      // @ts-expect-error still works
      TransitionComponent={FullScreenSheetTransition}
      fullScreen={fullScreen}
      open={Boolean(open)}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      disableBackdropClick={disableBackdropClick}
      {...props}
    >
      {!hideTitle && (title || fullScreen) && (
        <div
          className={cx(
            classes.titleContainer,
            titleBorder && classes.titleBorder,
          )}
        >
          {onClose && (
            <IconButton
              color='inherit'
              className={classes.closeButton}
              onClick={onClose}
              size='large'
            >
              <Close />
            </IconButton>
          )}
          {onBack && (
            <IconButton
              color='inherit'
              className={classes.closeButton}
              onClick={onBack}
              size='large'
            >
              <ChevronLeft />
            </IconButton>
          )}
          <Typography
            noWrap
            className={classes.title}
            variant={smallTitle ? 'subtitle1' : 'h6'}
          >
            {title ?? ''}
          </Typography>
          {rightContent != null && (
            <div className={classes.right}>{rightContent}</div>
          )}
        </div>
      )}
      {children}
    </Dialog>
  )
}

export default FullScreenSheet
