import {
  Box,
  Fade,
  IconButton,
  SvgIcon,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Edit, PeopleAlt, PersonAdd, Reply } from '@mui/icons-material'
import { setLocBeforeEdit } from 'actions/event'
import { setCurrentScrapeBotId } from 'actions/scraper'
import ActionOverflow from 'components/event/actions/ActionOverflow'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getIsEventOwner, getIsEventsAdmin } from 'selectors/event'
import useMixpanelTrackEvent from 'utils/useMixpanelTrackEvent'
import {
  openCreatePostModal,
  openGuestManagement,
  openInviteGuestsModal,
  openModal,
  openShareModal,
} from '../../actions/modals'
import Column from '../../components/Column'
import Row from '../../components/Row'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../buttons/BashButton'
import { Theme } from '@mui/material/styles'
import GuestManagementTooltip from '../../components/modals/Share/GuestManagementTooltip'
import { useUser } from '../../utils/userFunctions'
import dayjs from 'dayjs'
import { getIsMobileOrTablet } from '../../utils/isMobileOrTablet'
import ShortcutTooltip from '../../components/common/ShortcutTooltip'
import { mdiBullhorn, mdiChartTimelineVariantShimmer, mdiFire } from '@mdi/js'
import BashIconButton from 'shared-components/buttons/BashIconButton'

const EditEventHeader = ({ event }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const isEventsAdmin = useSelector(getIsEventsAdmin)
  const [showGuestTooltip, setShowGuestTooltip] = useState(false)
  const { user } = useUser()
  const isOwner = useSelector(getIsEventOwner)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const trackEvent = useMixpanelTrackEvent(event)
  const router = useRouter()

  const onEditClicked = () => {
    trackEvent('Edit Bash')
    dispatch(setLocBeforeEdit(router.asPath))
    dispatch(setCurrentScrapeBotId(null))
    router.push(`/edit/${event.code}`)
  }

  const inviteClicked = () => {
    dispatch(openInviteGuestsModal())
  }

  const shareClicked = () => {
    dispatch(openShareModal())
  }

  const messageClicked = () => {
    dispatch(openCreatePostModal(false, null, [], true))
  }

  const manageGuestsClicked = () => {
    dispatch(openGuestManagement('guestList'))
    onGuestTooltipClose()
  }

  const onInsightsClicked = () => {
    dispatch(openModal('insights'))
  }

  const keydownHandler = (e) => {
    if (
      e.target.tagName === 'INPUT' ||
      e.target.tagName === 'TEXTAREA' ||
      e.target.isContentEditable
    ) {
      return
    }
    if (
      (e.key === 'u' || e.key === 'U') &&
      !e.ctrlKey &&
      !e.metaKey &&
      !e.altKey
    ) {
      messageClicked()
    }
    if (
      e.key === 's' ||
      (e.key === 'S' && !e.ctrlKey && !e.metaKey && !e.altKey)
    ) {
      shareClicked()
    }
    if (
      (e.key === 'm' || e.key === 'M') &&
      !e.ctrlKey &&
      !e.metaKey &&
      !e.altKey
    ) {
      manageGuestsClicked()
    }
    if (
      (e.key === 'e' || e.key === 'E') &&
      !e.ctrlKey &&
      !e.metaKey &&
      !e.altKey
    ) {
      onEditClicked()
    }
    if (
      (e.key === 'i' || e.key === 'I') &&
      !e.ctrlKey &&
      !e.metaKey &&
      !e.altKey
    ) {
      inviteClicked()
    }

    if (
      isEventsAdmin &&
      (e.key === 'v' || e.key === 'V') &&
      !e.ctrlKey &&
      !e.metaKey &&
      !e.altKey
    ) {
      onInsightsClicked()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', keydownHandler)
    return () => {
      document.removeEventListener('keydown', keydownHandler)
    }
  }, [])

  const onGuestTooltipClose = () => {
    setShowGuestTooltip(false)
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('closedGuestManagementTooltip', 'true')
    }
  }

  useEffect(() => {
    if (
      user &&
      dayjs(user.createdAt).isBefore(dayjs('2025-01-20')) &&
      dayjs().isBefore(dayjs('2025-02-20')) &&
      !getIsMobileOrTablet()
    ) {
      if (typeof window !== 'undefined') {
        const hasClosedTooltip = window.localStorage.getItem(
          'closedGuestManagementTooltip',
        )
        if (hasClosedTooltip) {
          setShowGuestTooltip(false)
        } else {
          setShowGuestTooltip(true)
        }
      }
    }
  }, [user])

  const buttonSx: SxProps<Theme> = (theme) => ({
    px: '8px !important',
    color:
      (theme.extras.name === 'custom'
        ? theme.extras.darkMode
          ? 'white'
          : theme.palette.grey[100]
        : theme.palette.primary.main) + ' !important',
    background:
      (theme.extras.name === 'custom'
        ? theme.extras.darkMode
          ? theme.palette.primary.dark
          : theme.palette.grey[800]
        : theme.palette.primary[100]) + ' !important',
    '&:hover': {
      background:
        (theme.extras.name === 'custom'
          ? theme.extras.darkMode
            ? theme.palette.primary.main
            : theme.palette.grey[600]
          : theme.palette.primary[200]) + ' !important',
    },
  })

  const iconButtonSx: SxProps<Theme> = (theme) => ({
    ...buttonSx(theme),
    width: 40,
    height: 40,
  })

  return (
    <Column
      sx={(theme) => ({
        gap: 1.25,
        width: '100%',
        alignItems: 'center',
        [theme.breakpoints.down(870)]: {
          px: 2,
          gap: 2,
          mt: 1.5,
        },
      })}
    >
      <Typography
        sx={{
          color: 'primary.main',
        }}
        variant='overline'
      >
        {t('youHaveManageAccess')}
      </Typography>

      <Row
        sx={(theme) => ({
          px: 1,
          py: 0.75,
          borderRadius: 1,
          width: '100%',
          gap: 1,
          background:
            theme.extras.name === 'custom'
              ? theme.extras.darkMode
                ? theme.palette.primary.dark
                : theme.palette.grey[800]
              : theme.palette.primary[100],
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        })}
      >
        <Row
          sx={(theme) => ({
            gap: 1,
            flexWrap: 'wrap',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              gap: 0.5,
            },
          })}
        >
          <ShortcutTooltip title={t('invite')} shortcut={'I'}>
            <div>
              {isMobile && (
                <BashIconButton
                  onClick={inviteClicked}
                  type={BashButtonType.PRIMARY_LIGHT}
                  sx={buttonSx}
                >
                  <PersonAdd />
                </BashIconButton>
              )}
              {!isMobile && (
                <BashButton
                  type={BashButtonType.PRIMARY_LIGHT}
                  onClick={inviteClicked}
                  extraView={<PersonAdd />}
                  viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
                  sx={buttonSx}
                >
                  {t('invite')}
                </BashButton>
              )}
            </div>
          </ShortcutTooltip>

          <ShortcutTooltip title={t('share')} shortcut={'S'}>
            <div>
              {isMobile && (
                <BashIconButton
                  onClick={shareClicked}
                  type={BashButtonType.PRIMARY_LIGHT}
                  sx={buttonSx}
                >
                  <Reply sx={{ transform: 'scaleX(-1)' }} />
                </BashIconButton>
              )}
              {!isMobile && (
                <BashButton
                  type={BashButtonType.PRIMARY_LIGHT}
                  onClick={shareClicked}
                  extraView={<Reply sx={{ transform: 'scaleX(-1)' }} />}
                  viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
                  sx={buttonSx}
                >
                  {t('share')}
                </BashButton>
              )}
            </div>
          </ShortcutTooltip>

          <ShortcutTooltip title={t('sendUpdate')} shortcut={'U'}>
            <div>
              {isMobile && (
                <BashIconButton
                  onClick={messageClicked}
                  type={BashButtonType.PRIMARY_LIGHT}
                  sx={buttonSx}
                >
                  <SvgIcon style={{ width: 20, height: 20 }}>
                    <path d={mdiBullhorn} />
                  </SvgIcon>
                </BashIconButton>
              )}
              {!isMobile && (
                <BashButton
                  type={BashButtonType.PRIMARY_LIGHT}
                  onClick={messageClicked}
                  extraView={
                    <SvgIcon style={{ width: 20, height: 20 }}>
                      <path d={mdiBullhorn} />
                    </SvgIcon>
                  }
                  viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
                  sx={buttonSx}
                >
                  {t('sendUpdate')}
                </BashButton>
              )}
            </div>
          </ShortcutTooltip>
        </Row>

        <Row
          sx={(theme) => ({
            gap: 1,
            flexWrap: 'wrap',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              gap: 0.5,
            },
          })}
        >
          {isEventsAdmin && (
            <ShortcutTooltip title={t('insights')} shortcut={'V'}>
              <IconButton sx={iconButtonSx} onClick={onInsightsClicked}>
                <SvgIcon fontSize='small'>
                  <path d={mdiChartTimelineVariantShimmer} />
                </SvgIcon>
              </IconButton>
            </ShortcutTooltip>
          )}

          <ShortcutTooltip title={t('editEvent')} shortcut={'E'}>
            <IconButton sx={iconButtonSx} onClick={onEditClicked}>
              <Edit fontSize='small' />
            </IconButton>
          </ShortcutTooltip>

          <Box
            sx={{
              position: 'relative',
            }}
          >
            <ShortcutTooltip title={t('manageGuests')} shortcut={'M'}>
              <IconButton sx={iconButtonSx} onClick={manageGuestsClicked}>
                <PeopleAlt fontSize={'small'} />
              </IconButton>
            </ShortcutTooltip>
            <Fade in={showGuestTooltip}>
              <Box
                sx={{
                  position: 'absolute !important',
                  top: 32,
                  right: -16,
                  width: 320,
                  zIndex: 100,
                }}
              >
                <GuestManagementTooltip sx={{}} onClose={onGuestTooltipClose} />
              </Box>
            </Fade>
          </Box>

          <ActionOverflow
            location='web_event_page'
            items={
              isEventsAdmin
                ? [
                    'manageGuests',
                    'analytics',
                    // 'embed',
                    'duplicate',
                    ...(event.scrapeBotId ? ['reject'] : []),
                    'merge',
                    'cancel',
                  ]
                : isOwner
                  ? [
                      'manageGuests',
                      'analytics',
                      'embed',
                      'duplicate',
                      'cancel',
                    ]
                  : event.host
                    ? ['manageGuests', 'analytics', 'embed']
                    : []
            }
            small
            tiny={undefined}
            // @ts-expect-error sx type
            sx={iconButtonSx}
          />
        </Row>
      </Row>
    </Column>
  )
}

export default EditEventHeader
