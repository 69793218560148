import { Box, SvgIcon, Tooltip, Typography, useTheme } from '@mui/material'
import AvatarGroup from '../../shared-components/avatar/AvatarGroup'
import { makeStyles } from 'tss-react/mui'
import Link from 'next/link'
import React from 'react'
import VerifiedIcon from '../../svg/CheckDecagram'
import { Trans, useTranslation } from 'react-i18next'
import { formatShortNumber } from 'utils/eventFunctions'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'
import { useUser } from 'utils/userFunctions'
import { TrendingPageBadge } from 'components/EventCards/Badges'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from 'selectors/event'
import { VisibilityOffOutlined } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
    gap: theme.spacing(1.5),
    flexShrink: 0,
    width: '100%',
    background: theme.palette.background.secondary,
  },
  border: {
    border: '1px solid ' + theme.palette.divider,
    borderRadius: '8px',
    padding: theme.spacing(1.5, '10px'),
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '100px',
    border: `1px solid ${theme.palette.divider}`,
    background: 'white',
  },
  name: {
    fontWeight: 500,
    lineHeight: '1.1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
  verifiedIcon: {
    width: 16,
    height: 16,
  },
  button: {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary.main,
    height: 32,
    marginLeft: theme.spacing(0.5),
  },
  following: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.tertiary,
  },
  followerCount: {
    color: theme.palette.text.tertiary,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 6,
    minWidth: 0,
    flex: 1,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    minWidth: 0,
  },
  guestList: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    minWidth: 0,
    overflow: 'hidden',
  },
  friendAvatar: {
    width: '24px',
    height: '24px',
    borderRadius: '100px',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  friendVisited: {
    color: theme.palette.grey[800],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  boldText: {
    fontWeight: 'bold',
  },
}))

const HostCard = ({
  host,
  noBorder,
  onFollowClick,
  following,
  noFollow = false,
  noSocial = false,
  noBackgroundColor = false,
  width = '100%',
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const event = useSelector(getCurrentEvent)
  const avatarUrl =
    host.model?.avatarUrls?.sm ||
    host.model?.user?.avatarUrls?.sm ||
    host.model?.avatarUrls?.lg ||
    host.model?.user?.avatarUrls?.lg
  const name = host.model?.name || host.model?.user?.name
  const verified = host.type === 'ORGANISATION' && host.model?.verified
  const pending = host.state === 'PENDING'

  const { user } = useUser()
  const theme = useTheme()

  const showFollowButton =
    host.type === 'ORGANISATION' &&
    host.model?.followStatus !== 'ACTIVE' &&
    !noFollow

  const getLink = () => {
    if (host.type === 'ORGANISATION') {
      if (!!host.model?.username) {
        return `/${host.model?.username}`
      } else {
        return `/pages/${host.model?.id}`
      }
    } else if (host.type === 'USER') {
      return `/users/${host.model?.id}`
    } else {
      return '#'
    }
  }

  const renderSubLine = () => {
    if (host.model.visitedBy) {
      const avatarsToShow = [...host.model.visitedBy.friends.data]
      if (host.model.visitedBy.you && user) {
        avatarsToShow.unshift({
          id: user.id,
          avatarUrls: user.avatarUrls,
          name: user.name,
        })
      }

      const getVisitedText = () => {
        const friendsCount = host.model.visitedBy.friends.count
        if (host.model.visitedBy.you) {
          if (friendsCount === 0) {
            return t('youVisited')
          }
          if (friendsCount === 1) {
            return t('youAndOneVisited', {
              name: host.model.visitedBy.friends.data[0].name,
            })
          }
          return t('youAndManyVisited', {
            first: host.model.visitedBy.friends.data[0].name,
            count: friendsCount - 1,
          })
        } else {
          if (friendsCount === 1) {
            return t('oneVisited', {
              name: host.model.visitedBy.friends.data[0].name,
            })
          }
          if (friendsCount === 2) {
            return t('twoVisited', {
              first: host.model.visitedBy.friends.data[0].name,
              second: host.model.visitedBy.friends.data[1].name,
            })
          }
          return t('manyVisited', {
            name: host.model.visitedBy.friends.data[0].name,
            count: friendsCount - 1,
          })
        }
      }

      return (
        <div className={classes.guestList}>
          <AvatarGroup max={3} size='medium' smallGap noOverflow>
            {avatarsToShow.map((person) => (
              <img
                key={person.id}
                className={classes.friendAvatar}
                src={person.avatarUrls?.sm || person.avatarUrls?.lg}
                alt={`${person.id} avatar`}
              />
            ))}
          </AvatarGroup>
          <Tooltip
            title={
              <Typography variant='caption'>
                <Trans
                  i18nKey={getVisitedText()}
                  components={{ strong: <span className={classes.boldText} /> }}
                />
              </Typography>
            }
          >
            <Typography
              variant='caption'
              className={classes.friendVisited}
              style={{ color: theme.palette.text.secondary }}
            >
              <Trans
                i18nKey={getVisitedText()}
                components={{ strong: <span className={classes.boldText} /> }}
              />
            </Typography>
          </Tooltip>
        </div>
      )
    }
    if (
      host.model?.followerCount > 100 &&
      host.model?.followerCount > host.model?.guestCount
    ) {
      return (
        <Typography className={classes.followerCount} variant='caption'>
          {formatShortNumber(host.model?.followerCount + (following ? 1 : 0))}{' '}
          {t('shared:followers')}
        </Typography>
      )
    }
    if (
      host.model?.guestCount > 100 &&
      host.model?.guestCount > host.model?.followerCount
    ) {
      return (
        <Typography className={classes.followerCount} variant='caption'>
          {formatShortNumber(host.model?.guestCount + (following ? 1 : 0))}{' '}
          {t('shared:attendees')}
        </Typography>
      )
    }
  }

  return (
    <Link href={getLink()} legacyBehavior>
      <div
        className={cx(classes.root, !noBorder && classes.border)}
        style={{
          background: noBackgroundColor
            ? 'transparent'
            : event.theme.colourScheme
              ? theme.palette.background.secondary
              : undefined,
          width: width,
        }}
      >
        <Box
          component='img'
          sx={{
            opacity: pending ? 0.5 : 1,
          }}
          className={classes.avatar}
          src={avatarUrl}
          alt='profile picture'
        />
        <div className={classes.info}>
          <div className={cx(classes.nameContainer)}>
            <Typography
              sx={{
                color: pending ? 'text.secondary' : 'text.primary',
              }}
              noWrap
              className={cx(classes.name)}
              variant='body2'
            >
              {name}
            </Typography>
            {verified && (
              <SvgIcon
                component={VerifiedIcon}
                className={classes.verifiedIcon}
                color='primary'
                sx={{
                  opacity: pending ? 0.5 : 1,
                }}
              />
            )}
          </div>
          {!pending && !noSocial && renderSubLine()}
          {!pending && !noSocial && host.model?.hot && <TrendingPageBadge />}
          {pending && (
            <Typography
              sx={{
                color: 'text.secondary',
              }}
              variant='caption'
            >
              {t('cohostRequestPending')}
            </Typography>
          )}
        </div>
        {showFollowButton && (
          <BashButton
            type={BashButtonType.PRIMARY_LIGHT}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onFollowClick(host)
            }}
            height={32}
          >
            {following ? t('following') : t('follow')}
          </BashButton>
        )}
        {pending && (
          <Tooltip title={t('cohostsNotVisibleTooltip')}>
            <VisibilityOffOutlined
              sx={{
                width: 16,
                height: 16,
                color: 'grey.600',
              }}
            />
          </Tooltip>
        )}
      </div>
    </Link>
  )
}

export default HostCard
