import { makeStyles } from 'tss-react/mui'
import { getVideoUrl } from '../../../shared-components/utils/media'
import { Typography } from '@mui/material'
import React, { useState } from 'react'
import { ViewMediaModal } from '../../modals/ViewMediaModal'
import { Close } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
  mediaContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  mediaItem: {
    cursor: 'pointer',
    borderRadius: 8,
    objectFit: 'cover',
    overflow: 'hidden',
    aspectRatio: '1',
    maxHeight: 88,
    flexShrink: 0,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    '& span': {
      width: '100%',
      height: '100%',
    },
  },
  mediaItemContainer: {
    position: 'relative',
  },
  mediaOverflowContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    borderRadius: 12,
    cursor: 'pointer',
  },
  removeButton: {
    position: 'absolute',
    top: -6,
    right: -6,
    cursor: 'pointer',
    width: 18,
    height: 18,
    padding: 2,
    background: '#A6A6A6',
    borderRadius: 100,
    zIndex: 10,
    color: 'white',
  },
}))

interface MultimediaRowProps {
  media: any[]
  onRemoveImageClicked: ((media: any) => void) | undefined
  limit: number | undefined
}

const MultimediaRow = ({
  media,
  onRemoveImageClicked,
  limit = 3,
}: MultimediaRowProps) => {
  const { classes } = useStyles()
  const [fullscreenOpen, setFullscreenOpen] = useState(false)
  const [activeImageIdx, setActiveImageIdx] = useState(0)

  const onMediaClicked = (idx) => {
    setActiveImageIdx(idx)
    setFullscreenOpen(true)
  }

  if (media.length === 0) {
    return null
  }

  return (
    <div className={classes.mediaContainer}>
      {media.slice(0, limit).map((m, index) => (
        <div
          key={index}
          className={classes.mediaItemContainer}
          onClick={() => onMediaClicked(index)}
        >
          <div className={classes.mediaItem} key={index}>
            {onRemoveImageClicked && (
              <Close
                className={classes.removeButton}
                onClick={(e) => {
                  e.stopPropagation()
                  onRemoveImageClicked(m)
                  setActiveImageIdx(0)
                }}
              />
            )}
            {m.type === 'VIDEO' && (
              <div>
                {/* {m.content.thumbnail && (
                  <img src={m.content.thumbnail.url} draggable={false} />
                )}
                {!m.content.thumbnail && ( */}
                <video playsInline autoPlay src={getVideoUrl(m)} muted loop />
                {/* )} */}
              </div>
            )}
            {m.type === 'IMAGE' && (
              <img src={m.content.imageUrls.lg} alt='image' />
            )}
          </div>
          {media.length > limit && index === limit - 1 && (
            <div className={classes.mediaOverflowContainer}>
              <Typography variant='h6'>+{media.length - limit}</Typography>
            </div>
          )}
        </div>
      ))}

      <ViewMediaModal
        open={fullscreenOpen}
        onClose={() => setFullscreenOpen(false)}
        media={media}
        activeImageIndex={activeImageIdx}
        setActiveImageIndex={setActiveImageIdx}
        useKeypress={true}
        onDeleteImage={
          onRemoveImageClicked &&
          (() => {
            if (media.length === 1) {
              setFullscreenOpen(false)
            }
            onRemoveImageClicked(media[activeImageIdx])
            setActiveImageIdx(0)
          })
        }
      />
    </div>
  )
}

export default MultimediaRow
