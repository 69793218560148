import { makeStyles } from 'tss-react/mui'
import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import AnimateHeight, { Height } from 'react-animate-height'

const animDurationMs = 200

const useStyles = makeStyles<{ overflow: 'visible' | 'hidden' }>()(
  (theme, { overflow }) => ({
    heightAnimate: {
      overflow: overflow + ' !important',
    },
    root: {
      position: 'relative',
      overflow: overflow,
    },
    enter: {
      position: 'relative',
      opacity: 0,
      transform: 'scale(0.98)',
    },
    enterActive: {
      position: 'relative',
      opacity: 1,
      transform: 'scale(1)',
      transition: `opacity ${animDurationMs / 1000}s ease-in-out, transform ${animDurationMs / 1000}s ease-in-out`,
    },
    enterDone: {
      position: 'relative',
      transform: 'scale(1)',
    },
    exit: {
      position: 'absolute',
      top: 0,
      width: '100%',
      margin: '0 auto',
      opacity: 1,
      transform: 'scale(1)',
    },
    exitActive: {
      position: 'absolute',
      opacity: 0,
      transform: 'scale(0.98)',
      transition: `opacity ${animDurationMs / 1000}s ease-in-out, transform ${animDurationMs / 1000}s ease-in-out`,
    },
    exitDone: {
      position: 'absolute',
      opacity: 0,
      transform: 'scale(0.98)',
    },
  }),
)

interface AnimatedStateContentProps {
  getContent: (state) => React.JSX.Element
  state: any
  animateHeight?: boolean
  overflow?: 'visible' | 'hidden'
}

const AnimatedStateContent = ({
  getContent,
  state,
  animateHeight = true,
  overflow = 'visible',
}: AnimatedStateContentProps) => {
  const { classes } = useStyles({ overflow })

  const [height, setHeight] = useState<Height>('auto')
  const contentDiv = useRef<HTMLDivElement | null>(null)

  const [animating, setAnimating] = useState(false)

  useEffect(() => {
    const element = contentDiv.current as HTMLDivElement

    const resizeObserver = new ResizeObserver(() => {
      setHeight(element.clientHeight)
    })

    resizeObserver.observe(element)

    return () => resizeObserver.disconnect()
  }, [])

  useEffect(() => {
    setAnimating(true)
    setTimeout(() => setAnimating(false), animDurationMs)
  }, [state])

  return (
    <AnimateHeight
      className={classes.heightAnimate}
      duration={animating ? animDurationMs : 0}
      height={animateHeight ? height : 'auto'}
      contentRef={contentDiv}
      contentClassName={classes.root}
      disableDisplayNone
    >
      <TransitionGroup component={null}>
        <CSSTransition
          key={state}
          timeout={animDurationMs}
          classNames={{
            enterActive: classes.enterActive,
            enterDone: classes.enterDone,
            enter: classes.enter,
            exit: classes.exit,
            exitActive: classes.exitActive,
            exitDone: classes.exitDone,
          }}
        >
          {getContent(state)}
        </CSSTransition>
      </TransitionGroup>
    </AnimateHeight>
  )
}

export default AnimatedStateContent
