import React, { useContext, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getIsEventsAdmin } from '../../selectors/event'
import SvgIcon from '@mui/material/SvgIcon'
import VerifiedIcon from '../../svg/CheckDecagram'

import { useTranslation } from 'react-i18next'
import {
  getEndDateText,
  getEndTime,
  getStartDateText,
  getStartTime,
} from '../../shared-components/utils/eventDisplay'
import { Tooltip, Typography, useTheme } from '@mui/material'
import { useRouter } from 'next/router'
import { useCurrentEvent, useIsLoggedIn } from 'utils/hooks'
import { appContext } from '../../shared-components/event/App'
import { Lock, WarningOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import LargeModal from '../modals/LargeModal'
import FlatButton from '../../shared-components/buttons/FlatButton'
import EventRsvpBox from './EventRsvpBox'
import HypeButton from '../common/HypeButton'
import { ExternalBadge, NearCapacityBadge } from '../EventCards/Badges'
import MultimediaViewer from './media/MultimediaViewer'
import useMediaQuery from '@mui/material/useMediaQuery'

const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  containerMobile: {
    padding: theme.spacing(0, 2, 2, 2),
    // background: '#fff',
    paddingTop: 16,
  },
  ticketLinkContainer: {
    margin: theme.spacing(2, 0),
  },
  title: {
    fontWeight: 900,
    letterSpacing: '-0.02em',
    // textTransform: 'uppercase',
    wordBreak: 'break-word',
  },
  titleMobile: {
    fontSize: '1.5rem',
    lineHeight: '1.8125rem',
  },
  date: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '21.48px',
  },
  flex: {
    display: 'flex',
    gap: theme.spacing(1),
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  twoLines: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  center: {
    alignItems: 'center',
  },
  hostItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  hostImage: {
    width: 40,
    height: 40,
    borderRadius: '100px',
  },
  hostName: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: 'auto',
  },
  verifiedIcon: {
    marginLeft: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  lightButton: {
    height: 40,
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[600],
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary[400],
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(-2),
    maxWidth: '100vw',
    ' -ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none',
    '& :-webkit-scrollbar': {
      display: 'none',
    },
  },
  topContainer: {
    width: '100%',
  },
  privateBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    padding: '4px 6px',
    background: theme.palette.mode === 'light' ? '#F2F5FD' : '#0B1B41',
    flexShrink: 1,
    width: 'auto',
    borderRadius: 4,
  },
  privateIcon: {
    width: 16,
    height: 16,
    color: theme.palette.mode === 'light' ? '#0B1B41' : '#F2F5FD',
  },
  privateText: {
    color: theme.palette.mode === 'light' ? '#0B1B41' : '#F2F5FD',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: 8,
    gap: 8,
  },
  titleContainerMobile: {
    marginTop: theme.spacing(2),
  },
  statusButton: {
    marginLeft: theme.spacing(1),
  },
  badgesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  noPaddingTop: {
    paddingTop: 0,
  },
}))

const startDateNineMonthsInFuture = (event) => {
  if (event?.startDate == null) return false
  return dayjs(event.startDate).isAfter(dayjs().add(9, 'months'))
}

const EventDetailsCard = ({
  inDrawer,
  desktopMode,
  inWidget,
  forceShowRsvp,
}) => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useTranslation('common')
  const event = useCurrentEvent()
  const startText = getStartDateText(event)
  const endText = getEndDateText(event)
  const startTimeText = getStartTime(event)
  const endTimeText = getEndTime(event)

  const theme = useTheme()
  const useVerticalMediaViewer = useMediaQuery(theme.breakpoints.up(370))

  const previewType = useContext(appContext).previewType || null
  const isEventsAdmin = useSelector(getIsEventsAdmin)

  const isExternal = event.creationType === 'EXTERNAL'
  const isPrivate = event.privacyType === 'PRIVATE'

  const [allOpen, setAllOpen] = useState(false)
  const onFollowClick = () => {
    dispatch(openFollowModal())
  }

  const isLoggedIn = useIsLoggedIn()

  return (
    <div
      className={cx(
        classes.container,
        !desktopMode && !inWidget && classes.containerMobile,
        !isLoggedIn && classes.noPaddingTop,
      )}
    >
      {(!desktopMode || inDrawer) && (
        <MultimediaViewer
          inDrawer={inDrawer}
          vertical={useVerticalMediaViewer}
        />
      )}
      <div className={classes.topContainer}>
        <div className={classes.leftContainer}>
          <div
            className={cx(
              classes.titleContainer,
              (!desktopMode || inDrawer) && classes.titleContainerMobile,
            )}
          >
            <Typography
              className={cx(classes.title, !desktopMode && classes.titleMobile)}
              variant='h3'
            >
              {event.name ?? t('eventName')}
              {event.state === 'CANCELED' && t('cancelled')}
            </Typography>
            <HypeButton className={classes.statusButton} event={event} />
          </div>

          <div className={classes.marginTop}>
            <Typography
              className={cx(classes.date, desktopMode && classes.dateContainer)}
            >
              {`${startText}${startTimeText ? ` · ${startTimeText}` : ''}${
                endText == null ? '' : ` - ${endText}`
              }${endTimeText ? ` · ${endTimeText}` : ''}`}
            </Typography>
            {isEventsAdmin &&
              previewType != null &&
              startDateNineMonthsInFuture(event) && (
                <Tooltip title='Date is more than 9 months in the future!'>
                  <WarningOutlined
                    style={{
                      color: '#FFcc00',
                      height: 40,
                      width: 40,
                      marginRight: 'auto',
                    }}
                  />
                </Tooltip>
              )}
          </div>
        </div>
      </div>
      {(isExternal || isPrivate) && (
        <div className={classes.badgesContainer}>
          {isPrivate && (
            <div className={classes.privateBadge}>
              <Lock className={classes.privateIcon} />
              <Typography variant='caption' className={classes.privateText}>
                {t('private')}
              </Typography>
            </div>
          )}

          {event.nearCapacity && <NearCapacityBadge />}

          {isExternal && <ExternalBadge />}
        </div>
      )}
      <EventRsvpBox
        id={event.id}
        previewType={previewType}
        forceShowRsvp={forceShowRsvp}
      />
      <LargeModal
        alignStart
        title={t('shared:hostedBy')}
        open={allOpen}
        onClose={() => setAllOpen(false)}
      >
        {event.hosts?.map((host) => (
          <div key={host.id} className={classes.hostItem}>
            <img
              className={classes.hostImage}
              src={
                host.model?.avatarUrls?.lg ?? host.model?.user?.avatarUrls?.lg
              }
              alt='page picture'
            />
            <Typography className={classes.hostName} variant='subtitle2'>
              {host.model?.name ?? host.model?.user?.name}
              {host.model?.verified && (
                <SvgIcon
                  component={VerifiedIcon}
                  className={classes.verifiedIcon}
                  color='primary'
                />
              )}
            </Typography>

            {host.type === 'ORGANISATION' && (
              <FlatButton
                className={classes.button}
                size='small'
                color='grey'
                onClick={onFollowClick}
              >
                {host.model?.followStatus !== 'ACTIVE' && t('follow')}
                {host.model?.followStatus === 'ACTIVE' && t('following')}
              </FlatButton>
            )}
          </div>
        ))}
      </LargeModal>
    </div>
  )
}

export default EventDetailsCard
