import React, { useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import DropZone from '../../editEvent/DropZone'
import HalfTransparentButton from '../../editEvent/HalfTransparentButton'
import CameraIcon from '@mui/icons-material/CameraAlt'
import { useTranslation } from 'react-i18next'
import { Button, useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { ViewMediaModal } from '../../modals/ViewMediaModal'
import SwipeableViews from 'react-swipeable-views'
import { getVideoUrl } from '../../../shared-components/utils/media'
import { getCurrentEvent } from '../../../selectors/event'
import MultimediaViewerScroller from './MultimediaViewerScroller'
import Column from '../../Column'

const useStyles = makeStyles()((theme) => ({
  root: {
    maxHeight: '650px',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    // '& > div': {
    //   width: '100%',
    //   '& > div': {
    //     width: '100% !important',
    //   },
    // },
    '& button': {
      position: 'absolute',
      bottom: theme.spacing(1.5),
      right: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  minHeight: {
    minHeight: 100,
  },
  button: {
    whiteSpace: 'nowrap',
  },
  whiteButton: {
    border: '1px solid ' + theme.palette.divider,
  },
  eventImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
    borderRadius: '12px',
    border: '1px solid ' + theme.palette.divider,
    maxHeight: '560px',
    display: 'block',
  },
  dropZone: {
    // minHeight: '100px',
  },
  mediaContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(0),
    // maxWidth: 'min(360px, 100vw)',
    width: '100%',
    overflowX: 'auto',
    position: 'relative',
  },
  videoContainer: {
    position: 'relative',
    display: 'flex',
  },
  videoTag: {
    padding: 6,
    background: 'rgba(255,255,255,0.8)',
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
    position: 'absolute',
    zIndex: 10,
    borderRadius: 100,
  },
  videoTagSmall: {
    padding: 2,
    paddingLeft: 4,
    paddingRight: 4,
    background: 'rgba(255,255,255,0.8)',
    transform: 'scale(0.8)',
    transformOrigin: 'left center',
    top: 2,
    left: 4,
    position: 'absolute',
    zIndex: 10,
    borderRadius: 100,
    color: 'black',
  },
  nextPrevButton: {
    zIndex: 10,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    minWidth: 0,
    color: theme.palette.divider,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 100,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
    width: 24,
    height: 24,
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  nextButton: {
    right: theme.spacing(1),
  },
  previousButton: {
    left: theme.spacing(1),
  },
  scrollButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    minWidth: 0,
    background: 'white',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 100,
    width: 24,
    height: 24,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  scrollRight: {
    right: theme.spacing(1),
    transform: 'translateY(-50%)',
  },
  scrollLeft: {
    left: theme.spacing(1),
    transform: 'translateY(-50%)',
  },
  mediaContainerContainer: {
    position: 'relative',
  },
  removeButton: {
    position: 'absolute',
    top: 8,
    right: -6,
    cursor: 'pointer',
    width: 16,
    height: 16,
    padding: 2,
    background: theme.palette.grey[150],
    borderRadius: 100,
    zIndex: 10,
    color: 'white',
  },
  mediaItemContainer: {
    position: 'relative',
    paddingTop: 8,
  },
  uploadMoreContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(0.25),
  },
  uploadMoreIcon: {
    color: theme.palette.grey[500],
    width: 24,
    height: 24,
  },
  uploadMoreText: {
    fontSize: '0.6rem',
    width: 'auto !important',
    height: 'auto !important',
  },
  uploadMoreButton: {
    border: `dashed 1px ${theme.palette.grey[500]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[150],
    },
    marginLeft: theme.spacing(0.5),
  },
  mainViewerContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
    width: '100%',
  },
  mainViewerContainerVertical: {
    paddingRight: 68,
  },
}))

let currentIndex = 0

const MultimediaViewer = ({
  imageInputRef,
  onNewFile,
  onSetImageClicked,
  onRemoveImageClicked,
  mediaToUse,
  hideMainViewer,
  className,
  inDrawer,
  vertical = false,
  mediaSize = 48,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const [activeImageIdx, setActiveImageIdx] = useState(0)
  const [videoDurations, setVideoDurations] = useState([])
  const [showScrollLeft, setShowScrollLeft] = useState(false)
  const [showScrollRight, setShowScrollRight] = useState(false)
  const [detailOpen, setDetailOpen] = useState(false)
  const desktopMode = useMediaQuery('(min-width:900px)')
  const scrollRef = useRef(null)

  // Only show PENDING media if they have a local file URL, otherwise they have just failed
  const media = useMemo(
    () =>
      (
        (mediaToUse ?? event.media)?.filter(
          (m) =>
            (m.content.status === 'FINAL' ||
              m.content.status === 'CREATED' ||
              m.newUrl) &&
            !m.removed,
        ) ?? []
      ).sort((a, b) => a.position - b.position),
    [event.media, mediaToUse],
  )

  const showScroller = media.length > 1 || onSetImageClicked

  const firstRatio = media.length > 0 ? (media[0].content.ratio ?? 1) : 1

  const onMediaItemClick = (index) => {
    setActiveImageIdx(index)
  }

  const checkScroll = () => {
    setShowScrollLeft(scrollRef.current?.scrollLeft > 0)
    setShowScrollRight(
      scrollRef.current?.scrollWidth > scrollRef.current?.clientWidth &&
        scrollRef.current?.scrollLeft <
          scrollRef.current?.scrollWidth - scrollRef.current?.clientWidth,
    )
  }

  const loadVideo = (url) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
          resolve(this)
        }

        video.onerror = function () {
          reject('Invalid video. Please select a video file.')
        }

        video.src = url
      } catch (e) {
        reject(e)
      }
    })

  const getDurationFormat = (idx) => {
    const duration = videoDurations.find(
      (d) => d.id === media[idx].id,
    )?.duration

    const minutes = Math.floor(duration / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
    })
    const seconds = Math.floor(duration - minutes * 60).toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 2,
      },
    )
    return minutes + ':' + seconds
  }

  useEffect(() => {
    const loadVideos = async () => {
      let durations = []
      if (media != null) {
        for (const m of media) {
          if (m.type === 'VIDEO') {
            const existing = videoDurations.find((d) => d.id === m.id)
            const loaded = existing ?? (await loadVideo(getVideoUrl(m)))
            durations = [
              ...durations,
              {
                duration: loaded.duration,
                id: m.id,
              },
            ]
          }
        }
      }
      setVideoDurations(durations)
    }

    if (
      onSetImageClicked &&
      videoDurations.length !== media.filter((m) => m.type === 'VIDEO').length
    ) {
      loadVideos()
    }
    checkScroll()
  }, [media, videoDurations])

  const handleLeftClick = (e) => {
    if (e) {
      e.stopPropagation()
    }
    if (activeImageIdx > 0) {
      setActiveImageIdx(activeImageIdx - 1)
    } else {
      setActiveImageIdx(media.length - 1)
    }
  }

  const handleRightClick = (e) => {
    if (e) {
      e.stopPropagation()
    }
    if (activeImageIdx < media.length - 1) {
      setActiveImageIdx(activeImageIdx + 1)
    } else {
      setActiveImageIdx(0)
    }
  }

  return (
    <DropZone
      customAccept='video/mp4, video/quicktime, video/webm, image/jpeg, image/png, image/gif, image/webp'
      multiple
      fileInputRef={imageInputRef}
      onNewFile={onNewFile}
      className={cx(classes.dropZone, className)}
      disabled={!onSetImageClicked}
    >
      <Column sx={{ width: 1, alignItems: inDrawer ? 'center' : 'flex-start' }}>
        <div
          className={cx(
            classes.root,
            onSetImageClicked && !hideMainViewer && classes.minHeight,
          )}
          style={{
            maxWidth: inDrawer ? 400 : 'none',
          }}
        >
          {!vertical && media.length > 1 && (
            <Button
              className={cx(classes.nextPrevButton, classes.nextButton)}
              onClick={() => handleRightClick()}
            >
              <ChevronRight />
            </Button>
          )}
          {!vertical && media.length > 1 && (
            <Button
              className={cx(classes.nextPrevButton, classes.previousButton)}
              onClick={() => handleLeftClick()}
            >
              <ChevronLeft />
            </Button>
          )}

          {!hideMainViewer &&
            media &&
            media.length > activeImageIdx &&
            media[activeImageIdx] && (
              <div
                className={cx(
                  classes.mainViewerContainer,
                  vertical &&
                    showScroller &&
                    classes.mainViewerContainerVertical,
                )}
              >
                <SwipeableViews
                  index={activeImageIdx}
                  style={{
                    width: '100%',
                  }}
                  onChangeIndex={(index) => setActiveImageIdx(index)}
                  enableMouseEvents
                  resistance
                  onSwitching={(index) => {
                    setTimeout(() => {
                      currentIndex = index
                    })
                  }}
                >
                  {media.map((item, index) => (
                    <div
                      key={item.id}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (currentIndex === Math.round(currentIndex)) {
                          setDetailOpen(true)
                        }
                      }}
                    >
                      {item.type === 'VIDEO' ? (
                        <div className={classes.videoContainer}>
                          <video
                            src={getVideoUrl(item)}
                            className={classes.eventImage}
                            autoPlay
                            playsInline
                            loop
                            muted
                            style={{ aspectRatio: firstRatio }}
                          />
                          {onSetImageClicked && (
                            <Typography
                              variant='caption'
                              className={classes.videoTag}
                            >
                              {getDurationFormat(index)}
                            </Typography>
                          )}
                        </div>
                      ) : (
                        <img
                          src={item.content.imageUrls?.lg ?? item.model?.lg}
                          alt='Event image'
                          className={classes.eventImage}
                          style={{ aspectRatio: firstRatio }}
                          draggable={false}
                        />
                      )}
                    </div>
                  ))}
                </SwipeableViews>

                {vertical && showScroller && (
                  <MultimediaViewerScroller
                    media={media}
                    vertical
                    onRemoveImageClicked={onRemoveImageClicked}
                    onSetImageClicked={onSetImageClicked}
                    getDurationFormat={getDurationFormat}
                    onMediaItemClick={onMediaItemClick}
                    setActiveImageIdx={setActiveImageIdx}
                    mediaSize={mediaSize}
                  />
                )}
              </div>
            )}
          {!hideMainViewer && !mediaToUse && media.length === 0 && (
            <img
              alt=''
              className={classes.eventImage}
              src={event.imageUrls?.lg}
              style={{ aspectRatio: firstRatio }}
            />
          )}

          {!hideMainViewer && onSetImageClicked != null && (
            <HalfTransparentButton
              className={classes.button}
              onClick={onSetImageClicked}
              startIcon={<CameraIcon />}
            >
              {t('manageMedia')}
            </HalfTransparentButton>
          )}
        </div>
        {showScroller && !vertical && (
          <MultimediaViewerScroller
            media={media}
            vertical={false}
            onRemoveImageClicked={onRemoveImageClicked}
            onSetImageClicked={onSetImageClicked}
            getDurationFormat={getDurationFormat}
            onMediaItemClick={onMediaItemClick}
            setActiveImageIdx={setActiveImageIdx}
            mediaSize={mediaSize}
          />
        )}
        {onSetImageClicked != null &&
          mediaToUse?.length === 0 &&
          !event.media &&
          !event.imageUrls && (
            <HalfTransparentButton
              className={classes.button}
              onClick={onSetImageClicked}
              startIcon={<CameraIcon />}
            >
              {t('manageMedia')}
            </HalfTransparentButton>
          )}
        <ViewMediaModal
          open={detailOpen}
          onClose={() => setDetailOpen(false)}
          media={media}
          activeImageIndex={activeImageIdx}
          setActiveImageIndex={setActiveImageIdx}
          useKeypress={!mediaToUse || detailOpen}
        />
      </Column>
    </DropZone>
  )
}

export default MultimediaViewer
